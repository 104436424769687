<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/accounts/${$route.params.id}`" exact exact-active-class="active">Account</b-nav-item>
            <b-nav-item v-if="root" :to="`/accounts/${$route.params.id}/tenants`" exact exact-active-class="active">Tenants</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/attributes`" exact exact-active-class="active">Attributes<b-badge class="ml-2" variant="danger">Data</b-badge></b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/factors`" exact exact-active-class="active">Factors</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/controls`" exact exact-active-class="active">Controls</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/tokens`" exact exact-active-class="active">Tokens</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/events`" exact exact-active-class="active">Events</b-nav-item>
        </b-nav>
        <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
    </b-card-header>
    <b-card-header class="text-muted bg-light">
        <small>Metrics are currently processed every 5 minutes.</small>
    </b-card-header>
    <b-card-body v-if="tenants">
        <b-table id="table" outlined hover :items="tenants.items" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/tenants/${item.id}`)" class="mb-0" show-empty empty-text="This account has no tenants." empty-filtered-text="No tenants match your search. If more tenants are available you can load them by clicking 'Load More' below.">
            <template #cell(status)="data">
                <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
            </template>
        </b-table>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="$emit('load', 'tenants')">Refresh</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
        <b-button v-if="tenants" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? tenants.items.length) - page * 10, 0)">Next</b-button>
        <b-button v-if="tenants?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'tenants')" class="ml-2" :disabled="loading_more">Load More</b-button>
    </b-card-body>
    <b-card-footer v-if="tenants?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ tenants.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
{
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'subscription',
        label: 'Subscription',
        sortable: true
    },
    {
        key: 'metrics.maa',
        label: 'MAA',
        sortable: true
    },
    {
        key: 'metrics.mac',
        label: 'MAC',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'AccountTenants',

    /**
     * EVENTS
     */
     emits: [ 'load', 'next', 'disable', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading_more: String,
        tenants: Array,
        account: Object,
        root: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.tenants) {
                this.$emit('load', 'tenants', false);
            }
        }
    }
}
</script>