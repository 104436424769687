<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/factors/${$route.params.id}`" exact exact-active-class="active">Factor</b-nav-item>
            <b-nav-item v-if="['secret:id','otp'].includes(factor?.subtype) || factor?.subtype.startsWith('oauth2')" :to="`/factors/${$route.params.id}/attributes`" exact exact-active-class="active">Attributes</b-nav-item>
            <b-nav-item :to="`/factors/${$route.params.id}/events`" exact exact-active-class="active">Events</b-nav-item>
        </b-nav>
    </b-card-header>
    <b-card-body v-if="factor">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                    <b-form-input v-model="factor.id" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" :state="validField('label')" invalid-feedback="Please provide a valid label.">
                    <b-form-input v-model="factor.label" :state="validField('label')" :readonly="!canEdit()"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3">
                    <b-form-select v-model="factor.subtype" :options="subtypes" disabled></b-form-select>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                    <b-form-select v-model="factor.status" :options="canEdit() ? statuses.filter(status => ['ENABLED','DISABLED'].includes(status.value)) : statuses" :disabled="!canEdit()"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Score" label-align-sm="right" label-cols-sm="3" description="This is the security score a user will accumulate by succesfully passing this factor." :state="validField('score')" invalid-feedback="Please provide a valid score. The minimum is 0.">
                    <b-form-input v-model="factor.score" type="number" min="0" :state="validField('score')" :readonly="!canEdit()"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body v-if="factor.statistics">
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'statistics')">
                <b-img src="/img/icons/statistics.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h5 class="text-secondary mb-0 mr-2">Statistics</h5>
            </b-card-header>
            <b-collapse id="statistics" visible>
                <b-card-header class="text-muted bg-light">
                    <small>Statistics are currently processed every day at 5 AM UTC.</small>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Factors" label-align-sm="right" label-cols-sm="3" description="This is the total amount of factors of this factor (at the time of processing).">
                                <b-form-input v-model="factor.statistics.enrollments" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-body class="pb-0" v-if="factor.statistics.signups">
                    <b-row>
                        <b-col class="mb-4">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex">
                                    <b-img src="/img/icons/account-pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Signups</h5>
                                </b-card-header>
                                <b-card-body>
                                    <b-row>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Pending" label-align-sm="right" label-cols-sm="3" description="This is the number of pending signups within the current month.">
                                                <b-form-input v-model="factor.statistics.signups.pending" readonly></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Success" label-align-sm="right" label-cols-sm="3" description="This is the number of successful signups within the current month.">
                                                <b-form-input v-model="factor.statistics.signups.success" readonly></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Failed" label-align-sm="right" label-cols-sm="3" description="This is the number of failed signups within the current month.">
                                                <b-form-input v-model="factor.statistics.signups.failed" readonly></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-body class="pb-0" v-if="factor.statistics.logins">
                    <b-row>
                        <b-col class="mb-4">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex">
                                    <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Logins</h5>
                                </b-card-header>
                                <b-card-body>
                                    <b-row>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Pending" label-align-sm="right" label-cols-sm="3" description="This is the number of pending logins within the current month.">
                                                <b-form-input v-model="factor.statistics.logins.pending" readonly></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Success" label-align-sm="right" label-cols-sm="3" description="This is the number of successful logins within the current month.">
                                                <b-form-input v-model="factor.statistics.logins.success" readonly></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col xl="6" xxl="4">
                                            <b-form-group label="Failed" label-align-sm="right" label-cols-sm="3" description="This is the number of failed logins within the current month.">
                                                <b-form-input v-model="factor.statistics.logins.failed" readonly></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-footer v-if="factor.statistics.updated_at" class="text-muted bg-light">
                    <small>Last updated at {{ factor.statistics.updated_at.toLocaleString() }}</small>
                </b-card-footer>
            </b-collapse>
        </b-card>
    </b-card-body>
    <b-card-body v-if="factor?.config">
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
            </b-card-header>
            <b-collapse id="configuration" visible>
                <b-card-body>
                    <b-row>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Internal" label-align-sm="right" label-cols-sm="3" description="This indicates whether this factor is exclusive to internal accounts.">
                                <b-form-checkbox v-model="factor.config.internal" switch :disabled="!canEdit()"></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Restricted" label-align-sm="right" label-cols-sm="3" description="This indicates whether this factor is restricted to be created by administrators or the system only.">
                                <b-form-checkbox v-model="factor.config.restricted" switch :disabled="!canEdit()"></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Require Validation" label-align-sm="right" label-cols-sm="3" description="This indicates whether the factor must be validated before it gets enabled, i.e. whether users must prove they can use the factor.">
                                <b-form-checkbox v-model="factor.config.require_validation_for_enablement" switch :disabled="!canEdit() || factor.subtype.startsWith('oauth2') || factor.subtype === 'jwt:bearer'"></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4" v-if="factor.config.require_validation_for_enablement">
                            <b-form-group label="Expires In" label-align-sm="right" label-cols-sm="3" description="This is how long before a pending factor expires, i.e. how long users have to validate a pending factor.">
                                <b-form-input v-model="factor.config.expires_in" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-body>
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'input')">
                            <b-img src="/img/icons/input.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Input</h5>
                        </b-card-header>
                        <b-collapse id="input" visible>
                            <b-card-body>
                                <b-row>
                                    <b-col xl="6" xxl="4" v-if="factor.subtype.startsWith('oauth2')">
                                        <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the claim used as input.">
                                            <template #label>
                                                Claim<b-badge v-if="factor.config.scope.includes('openid')" class="ml-2 text-white" variant="openid">OpenID Connect</b-badge>
                                            </template>
                                            <b-form-input v-model="factor.config.claim" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="factor.config.regex !== null">
                                        <b-form-group label="Regex" label-align-sm="right" label-cols-sm="3" description="This is the regular expression (RegEx) to which the input must match.">
                                            <b-form-input v-model="factor.config.regex" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Unique" label-align-sm="right" label-cols-sm="3" description="This indicates whether each input must be unique. Note that only unique factors can be used as a first factor for login or signup.">
                                            <b-form-checkbox v-model="factor.config.unique" switch disabled></b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="factor.config.case_sensitive !== null">
                                        <b-form-group label="Case Sensitive" label-align-sm="right" label-cols-sm="3" description="This indicates whether the input is case sensitive.">
                                            <b-form-checkbox v-model="factor.config.case_sensitive" switch disabled></b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="factor.config.threshold != null">
                                        <b-form-group label="Threshold" label-align-sm="right" label-cols-sm="3" description="This is the security treshold that the input must meet.">
                                            <b-form-select v-model="factor.config.threshold" :options="thresholds" disabled></b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="['secret:id','otp'].includes(factor.subtype)">
                                        <b-form-group label-align-sm="right" label-cols-sm="3" description="This indicates whether the input should be captured upon enrollment and propagated as a data event. This setting is required if you want to source attributes from this factor.">
                                            <template #label>
                                                Capture Input<b-badge class="ml-2" variant="danger">Data</b-badge>
                                            </template>
                                            <b-form-checkbox v-model="factor.config.capture_input" switch :disabled="!canEdit()"></b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-card-body>
                <b-card-body v-if="factor.subtype.endsWith('otp')">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'otp')">
                            <b-img src="/img/factors/totp.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">One-Time Password</h5>
                        </b-card-header>
                        <b-collapse id="otp" visible>
                            <b-card-body>
                                <b-row>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="OTP Regex" label-align-sm="right" label-cols-sm="3" description="This is the regular expression (RegEx) by which One-Time Passwords (OTPs) are generated.">
                                            <b-form-input v-model="factor.config.otp" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="factor.config.issuer !== null">
                                        <b-form-group label="Issuer" label-align-sm="right" label-cols-sm="3" description="This is the service name for the entry in the Authenticator App." :state="validField('config.issuer')" invalid-feedback="Please provide a valid name.">
                                            <b-form-input v-model="factor.config.issuer" :state="validField('config.issuer')" :readonly="!canEdit()"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="factor.config.digits !== null">
                                        <b-form-group label="Digits" label-align-sm="right" label-cols-sm="3" description="This is the amount of digits for a one-time code.">
                                            <b-form-input v-model="factor.config.digits" type="number" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="factor.config.step !== null">
                                        <b-form-group label="Step" label-align-sm="right" label-cols-sm="3" description="This is the time in seconds how long before a new one-time code is to be generated.">
                                            <b-form-input v-model="factor.config.step" type="number" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="factor.config.window !== null">
                                        <b-form-group label="Window" label-align-sm="right" label-cols-sm="3" description="This is how many one-time codes from previous and future cycles are also valid.">
                                            <b-form-input v-model="factor.config.window" type="number" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-card-body>
                <b-card-body v-if="factor.subtype.startsWith('oauth2')">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'federation')">
                            <b-img src="/img/factors/oauth2/oidc.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Federation<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge><b-badge v-if="factor.config.scope.includes('openid')" class="ml-2 text-white" variant="openid">OpenID Connect</b-badge></h5>
                        </b-card-header>
                        <b-collapse id="federation" visible>
                            <b-card-body class="pb-0">
                                <b-row>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Client ID" label-align-sm="right" label-cols-sm="3" description="This is the ID for this client as provided by the Identity Provider (IDP)." :state="validField('config.client_id')" invalid-feedback="Please provide a client ID.">
                                            <b-form-input v-model="factor.config.client_id" :state="validField('config.client_id')" :readonly="!canEdit() || factor.subtype === 'oauth2:quasr'"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Authorization Endpoint" label-align-sm="right" label-cols-sm="3" description="This is the OAuth 2.0 authorization endpoint of the Identity Provider (IDP). Used to initiate an authorization request, i.e. start a login." :state="validField('config.authorization_endpoint')" invalid-feedback="Please provide a valid authorization endpoint. Must be an URL.">
                                            <b-form-input v-model="factor.config.authorization_endpoint" :state="validField('config.authorization_endpoint')" :readonly="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Scope" label-align-sm="right" label-cols-sm="3" description="These are the requested scopes. Note that 'openid' must be included to support OpenID Connect." :state="validField('config.scope')" invalid-feedback="Please provide a valid scope.">
                                            <b-form-input v-model="factor.config.scope" :state="validField('config.scope')" :readonly="!canEdit()"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Response Type" label-align-sm="right" label-cols-sm="3" description="This is what the Identity Provider (IDP) must return after a successful login. Note that a flow with an authorization code takes more steps but is more secure.">
                                            <b-form-select v-model="factor.config.response_type" :options="response_types" :disabled="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Response Mode" label-align-sm="right" label-cols-sm="3" description="This is how the Identity Provider (IDP) should return the authorization code or identity token. Note that this setting may be ignored by the Identity Provider (IDP).">
                                            <b-form-select v-model="factor.config.response_mode" :options="response_modes" :disabled="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label-align-sm="right" label-cols-sm="3" description="This indicates whether authorization requests are signed.">
                                            <template #label>
                                                Signed Request<b-badge class="ml-2" variant="warning">NEW</b-badge>
                                            </template>
                                            <b-form-checkbox v-model="factor.config.signed_request" switch :disabled="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-card-body v-if="['NONE','code','code id_token','id_token code'].includes(factor.config.response_type)">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'access')">
                                        <b-img src="/img/menu/controls.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2">Access<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge></h5>
                                    </b-card-header>
                                    <b-collapse id="access" visible>
                                        <b-card-body>
                                            <b-row>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="Token Endpoint" label-align-sm="right" label-cols-sm="3" description="This is the OAuth 2.0 token endpoint of the Identity Provider (IDP). Used to exchange an authorization code for tokens." :state="validField('config.token_endpoint')" invalid-feedback="Please provide a valid token endpoint. Must be an URL.">
                                                        <b-form-input v-model="factor.config.token_endpoint" :state="validField('config.token_endpoint')" :readonly="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="Content Type" label-align-sm="right" label-cols-sm="3" description="This is the content type used by the OAuth 2.0 token endpoint of the Identity Provider (IDP).">
                                                        <b-form-select v-model="factor.config.content_type" :options="content_types" :disabled="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-select>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="Code Challenge Method" label-align-sm="right" label-cols-sm="3" description="This is the code challenge method used for the Proof Key Code Exchange (PKCE). Note this is now required as part of OAuth 2.1.">
                                                        <b-form-select v-model="factor.config.code_challenge_method" :options="code_challenge_methods" :disabled="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-select>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4" v-if="factor.config.code_challenge_method !== 'NONE'">
                                                    <b-form-group label="Code Challenge Regex" label-align-sm="right" label-cols-sm="3" description="This is the regular expression (RegEx) by which code challenges are generated.">
                                                        <b-form-input v-model="factor.config.code_challenge_regex" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="Client Authentication" label-align-sm="right" label-cols-sm="3" description="This is the client authentication method.">
                                                        <b-form-select v-model="factor.config.client_authentication" :options="client_authentications" :disabled="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-select>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4" v-if="factor.config.client_authentication.startsWith('client_secret')">
                                                    <b-form-group label="Client Secret" label-align-sm="right" label-cols-sm="3" description="This is the shared secret for this client." :state="validField('config.client_secret')" invalid-feedback="Please provide a client secret.">
                                                        <b-form-input v-model="factor.config.client_secret" :state="validField('config.client_secret')" :readonly="!canEdit() || factor.subtype === 'oauth2:quasr'"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label-align-sm="right" label-cols-sm="3" description="This indicates whether tokens should be captured and propagated as a data event.">
                                                        <template #label>
                                                            Capture Tokens<b-badge class="ml-2" variant="danger">Experimental</b-badge>
                                                        </template>
                                                        <b-form-checkbox v-model="factor.config.capture_tokens" switch :disabled="!canEdit()"></b-form-checkbox>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                        <b-card-body class="pt-0" v-if="factor.config.client_authentication === 'private_key_jwt' && factor.subtype !== 'oauth2:quasr'">
                                            <b-card class="shadow rounded bg-white" no-body fluid>
                                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'keys')">
                                                    <b-img src="/img/factors/jwt/spki.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                    <h5 class="text-secondary mb-0 mr-2">Keys<b-badge class="ml-2" variant="warning">NEW</b-badge></h5>
                                                </b-card-header>
                                                <b-collapse id="keys" visible>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col xl="6" xxl="4" >
                                                                <b-form-group label="Private Key" label-align-sm="right" label-cols-sm="3" description="This is the private key for this client.">
                                                                    <b-input-group>
                                                                        <b-form-input v-model="factor.config.client_keys.private" :readonly="!canEdit()"></b-form-input>
                                                                        <b-input-group-append>
                                                                            <b-button variant="outline-primary" v-on:click="$emit('save', { subtype: 'jwt:spki', output: factor.config.client_keys.private })">Download</b-button>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col xl="6" xxl="4" v-if="factor.config.client_authentication === 'private_key_jwt'">
                                                                <b-form-group label="Public Key" label-align-sm="right" label-cols-sm="3" description="This is the public key for this client.">
                                                                    <b-input-group>
                                                                        <b-form-input v-model="factor.config.client_keys.public" :readonly="!canEdit()"></b-form-input>
                                                                        <b-input-group-append>
                                                                            <b-button variant="primary" v-on:click="$emit('save', { subtype: 'jwt:spki', output: factor.config.client_keys.public })">Download</b-button>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-card-body>
                            <b-card-body>
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'identity')">
                                        <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2">Identity<b-badge v-if="factor.config.scope.includes('openid')" class="ml-2 text-white" variant="openid">OpenID Connect</b-badge></h5>
                                    </b-card-header>
                                    <b-collapse id="identity" visible>
                                        <b-card-body>
                                            <b-row>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="Issuer" label-align-sm="right" label-cols-sm="3" description="This is the issuer of the identity token. Must match the 'iss' claim in the identity token." :state="validField('config.issuer')" invalid-feedback="Please provide an issuer.">
                                                        <b-form-input v-model="factor.config.issuer" :state="validField('config.issuer')" :readonly="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="JWKS Endpoint" label-align-sm="right" label-cols-sm="3" description="This is the JSON Web Key Set (JWKS) endpoint of the Identity Provider (IDP) which serves the keys used to validate the signature of the identity token." :state="validField('config.jwks_uri')" invalid-feedback="Please provide a valid JWKS endpoint. Must be an URL.">
                                                        <b-input-group>
                                                            <b-form-input v-model="factor.config.jwks_uri" :state="validField('config.jwks_uri')" :readonly="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-input>
                                                            <b-input-group-append>
                                                                <b-button :href="factor.config.jwks_uri" target="_blank" variant="outline-primary">Open</b-button>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="User Info Endpoint" label-align-sm="right" label-cols-sm="3" description="This is the (OpenID Connect) user info endpoint of the Identity Provider (IDP). Only used to obtain claims if no identity token is issued but only an access token." :state="factor.config.userinfo_endpoint ? validField('config.userinfo_endpoint') : undefined" invalid-feedback="Please provide a valid user info endpoint. Must be an URL.">
                                                        <b-form-input v-model="factor.config.userinfo_endpoint" :state="factor.config.userinfo_endpoint ? validField('config.userinfo_endpoint') : undefined" :readonly="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="Nonce" label-align-sm="right" label-cols-sm="3" description="This indicates whether a nonce must be included in the authorization request. Must match the reflected 'nonce' claim in the identity token.">
                                                        <b-form-checkbox v-model="factor.config.nonce" switch :disabled="!canEdit() || factor.subtype !== 'oauth2:oidc'"></b-form-checkbox>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4" v-if="factor.config.nonce">
                                                    <b-form-group label="Nonce Regex" label-align-sm="right" label-cols-sm="3" description="This is the regular expression (RegEx) by which nonces are generated.">
                                                        <b-form-input v-model="factor.config.nonce_regex" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label-align-sm="right" label-cols-sm="3" description="This indicates whether claims of the identity token should be captured and propagated as a data event. This setting is required if you want to source attributes from this factor.">
                                                        <template #label>
                                                            Capture Claims<b-badge class="ml-2" variant="danger">Data</b-badge>
                                                        </template>
                                                        <b-form-checkbox v-model="factor.config.capture_claims" switch :disabled="!canEdit()"></b-form-checkbox>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-card-body>
                <b-card-body v-if="factor.config.tokens && (factor.config.signed_request || factor.subtype === 'jwt:bearer' || factor.config.client_authentication === 'private_key_jwt')">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'tokens')">
                            <b-img src="/img/icons/token.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Tokens</h5>
                        </b-card-header>
                        <b-collapse id="tokens" visible>
                            <b-card-body class="pb-0">
                                <b-row>
                                    <b-col xl="6" xxl="4" class="mb-4" v-if="factor.config.signed_request">
                                        <b-card class="shadow rounded bg-white" no-body fluid>
                                            <b-card-header class="bg-light d-flex">
                                                <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                <h5 class="text-secondary mb-0 mr-2">Request<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge></h5>
                                            </b-card-header>
                                            <b-card-body>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long an authorization request token is valid, i.e. how fast the Identity Provider (IDP) must validate the signed request.">
                                                            <b-form-input v-model="factor.config.tokens.request.exp" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                            <b-form-select v-model="factor.config.tokens.request.use" :options="usages" disabled></b-form-select>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col xl="6" xxl="4" class="mb-4" v-if="factor.subtype === 'jwt:bearer' || factor.config.client_authentication === 'private_key_jwt'">
                                        <b-card class="shadow rounded bg-white" no-body fluid>
                                            <b-card-header class="bg-light d-flex">
                                                <b-img src="/img/icons/account-enabled.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                <h5 class="text-secondary mb-0 mr-2">Assertion<b-badge v-if="factor.subtype.startsWith('oauth2')" class="ml-2" variant="oauth2">OAuth 2.0</b-badge></h5>
                                            </b-card-header>
                                            <b-card-body>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long an assertion token is valid, i.e. how long the token can be used by the holder to attest their identity.">
                                                            <b-form-input v-model="factor.config.tokens.assertion.exp" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                            <b-form-select v-model="factor.config.tokens.assertion.use" :options="usages" disabled></b-form-select>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-card-body>
                <b-card-body>
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'security')">
                            <b-img src="/img/icons/security.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Security</h5>
                        </b-card-header>
                        <b-collapse id="security" visible>
                            <b-card-body>
                                <b-row>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Max Attempts" label-align-sm="right" label-cols-sm="3" description="This is the maximum amount of consecutive unsuccessful attempts before the factor gets temporarily locked.">
                                            <b-form-input v-model="factor.config.max_attempts" type="number" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Auto Unlock" label-align-sm="right" label-cols-sm="3" description="This is the time in seconds before a temporarily locked factor gets automatically unlocked.">
                                            <b-form-input v-model="factor.config.auto_unlock" type="number" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-card-body>
            </b-collapse>
        </b-card>
    </b-card-body>
    <b-card-body v-if="factor">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="factor.created_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="factor.created_by" readonly></b-form-input>
                        <b-badge v-if="factor.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${factor.created_by}`" variant="outline-primary" :disabled="factor.created_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="factor.updated_at">
                <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="factor.updated_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="factor.updated_by">
                <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="factor.updated_by" readonly></b-form-input>
                        <b-badge v-if="factor.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${factor.updated_by}`" variant="outline-primary" :disabled="factor.updated_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="factor.expires_at">
                <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="factor.expires_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="success" v-on:click="saveFactor()" :disabled="!canEdit() || !validFactor()">Save</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="$emit('load', 'factor')">Refresh</b-button>
        <b-button variant="outline-danger" class="ml-auto" v-on:click="$emit('show', 'delete-factor', factor)" :disabled="!canEdit()">Delete</b-button>
    </b-card-body>
    <b-card-footer v-if="factor?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ factor.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const SUBTYPES = [
    { value: 'secret:id', text: 'Username' },
    { value: 'secret:password', text: 'Password' },
    { value: 'otp', text: 'One-Time Password' },
    { value: 'totp', text: 'Authenticator App' },
    { value: 'jwt:bearer', text: 'Personal Token' },
    { value: 'jwt:spki', text: 'Private Key' },
    { value: 'jwt:jwks', text: 'Hosted Key Set'},
    { value: 'oauth2:quasr', text: 'Quasr' },
    { value: 'oauth2:apple', text: 'Apple' },
    { value: 'oauth2:slack', text: 'Slack' },
    { value: 'oauth2:github', text: 'GitHub' },
    { value: 'oauth2:google', text: 'Google' },
    { value: 'oauth2:discord', text: 'Discord' },
    { value: 'oauth2:linkedin', text: 'LinkedIn' },
    { value: 'oauth2:facebook', text: 'Facebook' },
    { value: 'oauth2:microsoft', text: 'Microsoft' },
    { value: 'oauth2:oidc', text: 'OpenID Connect' }
];
const THRESHOLDS = [
    { value: 0, text: 'None' },
    { value: 1, text: 'Low' },
    { value: 2, text: 'Medium' },
    { value: 3, text: 'High' },
    { value: 4, text: 'Very High' }
];
const RESPONSE_MODES = [
    { value: 'query', text: 'Query Parameters (GET)' },
    { value: 'form_post', text: 'Form Submission (POST)' },
    { value: 'NONE', text: 'No Preference' }
];
const RESPONSE_TYPES = [
    { value: 'code', text: 'Authorization Code' },
    { value: 'code id_token', text: 'Authorization Code & ID Token' },
    { value: 'id_token', text: 'Identity Token' },
    { value: 'NONE', text: 'No Preference' }
];
const CONTENT_TYPES = [
    { value: 'application/json', text: 'JSON' },
    { value: 'application/x-www-form-urlencoded', text: 'Form URL-Encoded' }
];
const CODE_CHALLENGE_METHODS = [
    { value: 'S256', text: 'Hash' },
    { value: 'plain', text: 'Cleartext' },
    { value: 'NONE', text: 'None' }
];
const CLIENT_AUTHENTICATIONS = [
    { value: 'client_secret_basic', text: 'Client Secret (Authorization Header)' },
    { value: 'client_secret_post', text: 'Client Secret (Body)' },
    { value: 'private_key_jwt', text: 'Private Key (JWT)' }
];
const USAGES = [
    { value: 0, text: 'Unlimited' },
    { value: 1, text: 'One-Time' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Factor',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'loading', 'load', 'next', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        factor: Object
    },

    /**
     * DATA
     */
    data() {
        return {
            // STATUSES
            statuses: STATUSES,
            // SUBTYPES
            subtypes: SUBTYPES,
            // THRESHOLDS
            thresholds: THRESHOLDS,
            // RESPONSE MODES
            response_modes: RESPONSE_MODES,
            // RESPONSE TYPES
            response_types: RESPONSE_TYPES,
            // CONTENT TYPES
            content_types: CONTENT_TYPES,
            // CODE CHALLENGE METHODS
            code_challenge_methods: CODE_CHALLENGE_METHODS,
            // CLIENT AUTHENTICATIONS
            client_authentications: CLIENT_AUTHENTICATIONS,
            // USAGES
            usages: USAGES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * METHODS
     */
    methods: {

        /**
         * FACTOR
         */
        async saveFactor() {
            this.$emit('loading', 'Saving');
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateFactor($input: UpdateFactorInput!) {
                                updateFactor(input: $input) {
                                    id
                                    label
                                    score
                                    status
                                    subtype
                                    config {
                                        unique
                                        internal
                                        restricted
                                        case_sensitive
                                        require_validation_for_enablement
                                        max_attempts
                                        auto_unlock
                                        expires_in
                                        regex
                                        threshold
                                        token_regex
                                        otp
                                        issuer
                                        digits
                                        step
                                        window
                                        authorization_endpoint
                                        userinfo_endpoint
                                        token_endpoint
                                        jwks_uri
                                        response_mode
                                        response_type
                                        content_type
                                        claim
                                        scope
                                        nonce
                                        nonce_regex
                                        code_challenge_method
                                        code_challenge_regex
                                        signed_request
                                        client_authentication
                                        client_id
                                        client_secret
                                        client_keys {
                                            private
                                            public
                                        }
                                        capture_claims
                                        capture_tokens
                                        capture_input
                                        tokens {
                                            request {
                                                exp
                                                use
                                            }
                                            assertion {
                                                exp
                                                use
                                            }
                                        }
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    statistics {
                                        enrollments
                                        signups {
                                            pending
                                            success
                                            failed
                                        }
                                        logins {
                                            pending
                                            success
                                            failed
                                        }
                                        updated_at
                                    }
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.factor.id}",
                                "subtype": "${this.factor.subtype}",
                                "label": "${this.factor.label}",
                                "status": "${this.factor.status}",
                                "score": ${this.factor.score},
                                "config": {
                                    "internal": ${this.factor.config.internal},
                                    "restricted": ${this.factor.config.restricted},
                                    "require_validation_for_enablement": ${this.factor.config.require_validation_for_enablement},
                                    "issuer": "${this.factor.config.issuer}",
                                    "client_id": "${this.factor.config.client_id}",
                                    "client_secret": "${this.factor.config.client_secret}",
                                    "capture_input": ${this.factor.config.capture_input},
                                    "capture_claims": ${this.factor.config.capture_claims},
                                    "capture_tokens": ${this.factor.config.capture_tokens},
                                    "scope": "${this.factor.config.scope}",
                                    "authorization_endpoint": "${this.factor.config.authorization_endpoint}",
                                    "response_type": "${this.factor.config.response_type}",
                                    "response_mode": "${this.factor.config.response_mode}",
                                    "signed_request": ${this.factor.config.signed_request},
                                    "token_endpoint": "${this.factor.config.token_endpoint}",
                                    "content_type": "${this.factor.config.content_type}",
                                    "code_challenge_method": "${this.factor.config.code_challenge_method}",
                                    "client_authentication": "${this.factor.config.client_authentication}",
                                    "jwks_uri": "${this.factor.config.jwks_uri}",
                                    "userinfo_endpoint": "${this.factor.config.userinfo_endpoint}",
                                    "nonce": ${this.factor.config.nonce}${this.factor.config.client_keys ? `,
                                    "client_keys": {
                                        "private": "${this.factor.config.client_keys.private}",
                                        "public": "${this.factor.config.client_keys.public}"
                                    }` : ''}
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const factor = (await response.json()).data.updateFactor;
                    this.$emit('load', 'factor');
                    this.$emit('alert', 'Your factor has been updated', 'Factor', 'success', 5000);
                    this.$emit('load', 'factors');
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save factor.', 'Factor', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save factor.', 'Factor', 'danger');
            }
            this.$emit('loading', undefined);
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED','DISABLED'].includes(this.factor?.status);
        },
        
        validFactor() {
            if (!this.validField('label')) return false;
            if (!this.validField('score')) return false;
            if (this.factor.subtype === 'totp') {
                if (!this.validField('config.issuer')) return false;
            } else if (this.factor.subtype.startsWith('oauth2')) {
                if (!this.validField('config.scope')) return false;
                if (!this.validField('config.client_id')) return false;
                if (this.factor.config.client_authentication.startsWith('client_secret')) {
                    if (this.factor.subtype !== 'oauth2:quasr' && !this.validField('config.client_secret')) return false;
                }
                if (this.factor.subtype === 'oauth2:oidc') {
                    if (!this.validField('config.authorization_endpoint')) return false;
                    if (this.factor.config.token_endpoint && !this.validField('config.token_endpoint')) return false;
                    if (!this.validField('config.issuer')) return false;
                    if (!this.validField('config.jwks_uri')) return false;
                    if (this.factor.config.userinfo_endpoint && !this.validField('config.userinfo_endpoint')) return false;
                }
            }
            return true;
        },

        validField(field) {
            switch (field) {
                case 'label':
                    return !!this.factor.label;
                case 'score':
                    return this.factor.score >= 0;
                case 'config.issuer':
                    return !!this.factor.config.issuer;
                case 'config.client_id':
                    return !!this.factor.config.client_id;
                case 'config.authorization_endpoint':
                    return !!this.factor.config.authorization_endpoint && this.isURL(this.factor.config.authorization_endpoint);
                case 'config.scope':
                    return !!this.factor.config.scope;
                case 'config.token_endpoint':
                    return !!this.factor.config.token_endpoint && this.isURL(this.factor.config.token_endpoint);
                case 'config.client_secret':
                    return !!this.factor.config.client_secret;
                case 'config.jwks_uri':
                    return !!this.factor.config.jwks_uri && this.isURL(this.factor.config.jwks_uri);
                case 'config.userinfo_endpoint':
                    return !!this.factor.config.userinfo_endpoint && this.isURL(this.factor.config.userinfo_endpoint);
                default:
                    return false;
            }
        },

        /**
         * URL
         */
        isURL(url) {
            try {
                new URL(url);
                return true;
            } catch (error) {
                return false;
            }
        }
    }
}
</script>