<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/tenant.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Tenants</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
                <b-button class="ml-2" variant="primary" v-on:click="$bvModal.show('set-filter')">Filter<b-badge class="ml-2" variant="warning">NEW</b-badge></b-button>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Metrics are currently processed every 5 minutes.</small>
            </b-card-header>
            <b-card-body v-if="$store.state.tenants">
                <b-table id="table" outlined hover :items="$store.state.tenants.items" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(item.id === $store.state.tenant_id ? '/tenant' : `/tenants/${item.id}`)" class="mb-0" show-empty empty-text="No tenants match your filter." empty-filtered-text="No tenants match your search. If more tenants are available you can load them by clicking 'Load More' below.">
                    <template #cell(account)="data">
                        <b-link :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                    </template>
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="listTenants()">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.tenants" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? $store.state.tenants.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.tenants?.nextToken" variant="outline-primary" v-on:click="nextTenants()" class="ml-2">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.tenants?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.tenants.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- SET FILTER -->
        <b-modal id="set-filter" title="Set Filter" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Creation Date (Min)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.created_at_min_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.created_at_min_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.created_at_min_date">
                <b-col>
                    <b-form-group label="Creation Time (Min)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.created_at_min_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.created_at_min_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Creation Date (Max)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.created_at_max_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.created_at_max_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.created_at_max_date">
                <b-col>
                    <b-form-group label="Creation Time (Max)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.created_at_max_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.created_at_max_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Expiration Date (Min)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.expires_at_min_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.expires_at_min_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.expires_at_min_date">
                <b-col>
                    <b-form-group label="Expiration Time (Min)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.expires_at_min_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.expires_at_min_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Expiration Date (Max)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.expires_at_max_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.expires_at_max_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.expires_at_max_date">
                <b-col>
                    <b-form-group label="Expiration Time (Max)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.expires_at_max_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.expires_at_max_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" description="Please note field is case sensitive.">
                        <b-input-group>
                            <b-form-input v-model="filter_input.label"></b-form-input>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.label" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Subscription" label-align-sm="right" label-cols-sm="3" description="Please note field is case sensitive.">
                        <b-input-group>
                            <b-form-input v-model="filter_input.subscription"></b-form-input>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.subscription" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                        <b-input-group>
                            <b-form-select v-model="filter_input.status" :options="filter_statuses"></b-form-select>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.status" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-row class="w-100">
                    <b-col class="d-flex px-0">
                        <b-button variant="outline-secondary" v-on:click="$bvModal.hide('set-filter')">Cancel</b-button>
                        <b-button variant="success" class="ml-auto" v-on:click="updateFilter()">Update</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'account',
        label: 'Account',
        sortable: true
    },
    {
        key: 'subscription',
        label: 'Subscription',
        sortable: true
    },
    {
        key: 'metrics.maa',
        label: 'MAA',
        sortable: true
    },
    {
        key: 'metrics.mac',
        label: 'MAC',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const FILTER_STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Tenants',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined,
            // LOADING (MORE)
            loading_more: false,
            // FILTER (INPUT)
            filter_input: {},
            // FILTER (LIST)
            filter_list: {},
            // STATUSES (FILTER)
            filter_statuses: FILTER_STATUSES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.$store.state.tenants) {
                this.updateFilter();
            }
        },

        /**
         * FILTER
         */
        updateFilter() {
            this.$bvModal.hide('set-filter');
            this.filter_list = {
                created_at: this.getTimeFilter('created_at'),
                expires_at: this.getTimeFilter('expires_at'),
                label: this.filter_input.label ? { contains: this.filter_input.label } : undefined,
                subscription: this.filter_input.subscription ? { contains: this.filter_input.subscription } : undefined,
                status: this.filter_input.status ? { eq: this.filter_input.status } : undefined
            };
            this.listTenants();
        },

        getTimeFilter(field) {
            if (this.filter_input[`${field}_min_date`]) {
                if (this.filter_input[`${field}_max_date`]) {
                    return { between: [ `${this.filter_input[`${field}_min_date`]}T${this.filter_input[`${field}_min_time`] || '00:00:00'}Z`, `${this.filter_input[`${field}_max_date`]}T${this.filter_input[`${field}_max_time`]|| '24:00:00'}Z`] }
                } else {
                    return { ge: `${this.filter_input[`${field}_min_date`]}T${this.filter_input[`${field}_min_time`] || '00:00:00'}Z` };
                }
            } else if (this.filter_input[`${field}_max_date`]) {
                return { le: `${this.filter_input[`${field}_max_date`]}T${this.filter_input[`${field}_max_time`] || '24:00:00'}Z` };
            } else {
                return undefined;
            }
        },

        /**
         * TENANTS
         */
        async listTenants() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listTenants($filter: TableTenantFilterInput, $limit: Int) {
                                listTenants(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        account
                                        subscription
                                        created_at
                                        metrics {
                                            maa
                                            mac
                                        }
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)}
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tenants = (await response.json()).data.listTenants;
                    // ADD REFRESH DATE
                    tenants.refreshed_at = new Date();
                    this.$store.commit('tenants', tenants);
                    // NOTIFY MORE AVAILABLE
                    if (tenants.nextToken) {
                        this.$emit('alert', 'More tenants are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Tenants', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tenants.', 'Tenants', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tenants.', 'Tenants', 'danger');
            }
            this.loading = undefined;
        },

        async nextTenants() {
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listTenants($filter: TableTenantFilterInput, $limit: Int, $nextToken: String) {
                                listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        account
                                        subscription
                                        created_at
                                        metrics {
                                            maa
                                            mac
                                        }
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)},
                            "nextToken": "${this.$store.state.tenants.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tenants = (await response.json()).data.listTenants;
                    // ADD NEW TENANTS
                    for (const tenant of tenants.items) {
                        this.$store.commit('push_tenant', tenant);
                    }
                    // SET NEXT TOKEN
                    this.$store.commit('set_tenants_token', tenants.nextToken);
                    // NOTIFY MORE AVAILABLE
                    if (tenants.nextToken) {
                        this.$emit('alert', 'More tenants are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Tenants', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tenants.', 'Tenants', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tenants.', 'Tenants', 'danger');
            }
            this.loading_more = false;
        }
    }
}
</script>