<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/controls.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Consent</h3>
            </b-card-header>
            <b-card-body v-if="consent">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="consent.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="consent.account" readonly></b-form-input>
                                <b-badge v-if="consent.account === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${consent.account}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="consent.label" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="$store.state.controls?.items">
                        <b-form-group label="Control" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-select v-model="consent.control" :options="$store.state.controls.items" value-field="id" text-field="label" disabled></b-form-select>
                                <b-input-group-append>
                                    <b-button :to="`/controls/${consent.control}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Recipient" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="consent.recipient" readonly></b-form-input>
                                <b-badge v-if="consent.recipient === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="consent.recipient === $store.state.tenant_id ? '/tenant' : `/accounts/${consent.recipient}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="consent.status" :options="statuses" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="consent">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="consent.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="consent.created_by" readonly></b-form-input>
                                <b-badge v-if="consent.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${consent.created_by}`" variant="outline-primary" :disabled="consent.created_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="consent.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="consent.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="consent.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="consent.updated_by" readonly></b-form-input>
                                <b-badge v-if="consent.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${consent.updated_by}`" variant="outline-primary" :disabled="consent.updated_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="consent.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="consent.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="getConsent()">Refresh</b-button>
                <b-button v-if="consent?.status === 'ENABLED'" variant="outline-danger" class="ml-auto" v-on:click="disableConsent()">Disable</b-button>
            </b-card-body>
            <b-card-footer v-if="consent?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ consent.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Consent',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // CONSENT
            consent: undefined,
            // STATUSES
            statuses: STATUSES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.consent) {
                    this.getConsent();
                }
                if (!this.$store.state.controls) {
                    this.$emit('load', 'controls', true); // LOAD ALL
                } else if (this.$store.state.controls.nextToken) {
                    this.$emit('next', 'controls', this.$store.state.controls.nextToken, true); // LOAD ALL
                }
            }
        },

        /**
         * CONSENT
         */
        async getConsent() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getConsent($id: ID!) {
                                getConsent(id: $id) {
                                    id
                                    label
                                    control
                                    account
                                    recipient
                                    status
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.consent = (await response.json()).data.getConsent;
                    // SET DATES
                    this.consent.created_at = this.consent.created_at ? new Date(this.consent.created_at) : undefined;
                    this.consent.updated_at = this.consent.updated_at ? new Date(this.consent.updated_at) : undefined;
                    this.consent.expires_at = this.consent.expires_at ? new Date(this.consent.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.consent.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain consent.', 'Consent', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain consent.', 'Consent', 'danger');
            }
            this.loading = undefined;
        },

        async disableConsent() {
            this.loading = 'Disabling';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateConsent($input: UpdateConsentInput!) {
                                updateConsent(input: $input) {
                                    id
                                    label
                                    control
                                    account
                                    recipient
                                    status
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.consent.id}",
                                "status": "DISABLED"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.consent = (await response.json()).data.updateConsent;
                    // SET DATES
                    this.consent.created_at = this.consent.created_at ? new Date(this.consent.created_at) : undefined;
                    this.consent.updated_at = this.consent.updated_at ? new Date(this.consent.updated_at) : undefined;
                    this.consent.expires_at = this.consent.expires_at ? new Date(this.consent.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.consent.refreshed_at = new Date();
                    this.$emit('alert', 'The consent has been disabled.', 'Consent', 'success', 5000);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to disable consent.', 'Consent', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to disable consent.', 'Consent', 'danger');
            }
            this.loading = undefined;
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED'].includes(this.consent?.status);
        }
    }
}
</script>