<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/extensions.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Extensions</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
                <b-button class="ml-2" variant="primary" v-on:click="$emit('show', 'create-extension', { label: 'Extension', config: { code: 'exports.handler = async function(event) {\n   console.log(event);\n}' } })">Create</b-button>
            </b-card-header>
            <b-card-body v-if="$store.state.extensions">
                <b-table id="table" outlined hover :items="$store.state.extensions.items" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/extensions/${item.id}`)" class="mb-0" show-empty empty-text="You have no extensions." empty-filtered-text="No extensions match your search. If more extensions are available you can load them by clicking 'Load More' below.">
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                    <template #cell(actions)="data">
                        <b-button variant="outline-danger" size="sm" :disabled="['PENDING','LOCKED'].includes(data.item.status)" v-on:click="$emit('show', 'delete-extension', data.item)">Delete</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="$emit('load', 'extensions')">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.extensions" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? $store.state.extensions.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.extensions?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'extensions', $store.state.extensions.nextToken)" class="ml-2">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.extensions?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.extensions.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'expires_at',
        label: 'Expires At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Extensions',

    /**
     * EVENTS
     */
     emits: [ 'alert', 'login', 'load', 'next', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.$store.state.extensions) {
                this.$emit('load', 'extensions');
            }
        }
    }
}
</script>