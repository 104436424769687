<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/tenant.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Tenant</h3>
            </b-card-header>
            <b-card-body v-if="tenant">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="tenant.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="tenant.account" readonly></b-form-input>
                                <b-badge v-if="tenant.account === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${tenant.account}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="tenant.label" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="tenant.status" :options="statuses" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Subscription" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="tenant.subscription" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="tenant?.metrics">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'metrics')">
                        <b-img src="/img/icons/metrics.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Metrics</h5>
                    </b-card-header>
                    <b-collapse id="metrics" visible>
                        <b-card-header class="text-muted bg-light">
                            <small>Metrics are currently processed every 5 minutes.</small>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="MAA" label-align-sm="right" label-cols-sm="3" description="These are the Monthly Active Accounts (MAA) for this tenant within the current month.">
                                        <b-form-input v-model="tenant.metrics.maa" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="MAC" label-align-sm="right" label-cols-sm="3" description="These are the Monthly API Calls (MAC) for this tenant within the current month.">
                                        <b-form-input v-model="tenant.metrics.mac" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer v-if="tenant.metrics.updated_at" class="text-muted bg-light">
                            <small>Last updated at {{ tenant.metrics.updated_at.toLocaleString() }}</small>
                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="tenant?.statistics">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'statistics')">
                        <b-img src="/img/icons/statistics.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Statistics</h5>
                    </b-card-header>
                    <b-collapse id="statistics" visible>
                        <b-card-header class="text-muted bg-light">
                            <small>Statistics are currently processed every day at 4 AM UTC.</small>
                        </b-card-header>
                        <b-card-body class="pb-0">
                            <b-row>
                                <b-col xl="6" xxl="4" class="mb-4">
                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                        <b-card-header class="bg-light d-flex">
                                            <b-img src="/img/icons/account-pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                            <h5 class="text-secondary mb-0 mr-2">Signups</h5>
                                        </b-card-header>
                                        <b-card-body>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Pending" label-align-sm="right" label-cols-sm="3" description="This is the number of started signups, i.e. pending accounts that got created, within the current month.">
                                                        <b-form-input v-model="tenant.statistics.signups.pending" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Success" label-align-sm="right" label-cols-sm="3" description="This is the number of successful signups, i.e. pending accounts that got enabled, within the current month.">
                                                        <b-form-input v-model="tenant.statistics.signups.success" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Failed" label-align-sm="right" label-cols-sm="3" description="This is the number of failed signups, i.e. pending accounts that expired, within the current month.">
                                                        <b-form-input v-model="tenant.statistics.signups.failed" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-card>
                                </b-col>
                                <b-col xl="6" xxl="4" class="mb-4">
                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                        <b-card-header class="bg-light d-flex">
                                            <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                            <h5 class="text-secondary mb-0 mr-2">Accounts</h5>
                                        </b-card-header>
                                        <b-card-body>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Pending" label-align-sm="right" label-cols-sm="3" description="This is the number of pending accounts, i.e. accounts that have not yet completed signup, at the time of processing.">
                                                        <b-form-input v-model="tenant.statistics.accounts.pending" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Enabled" label-align-sm="right" label-cols-sm="3" description="This is the number of enabled accounts at the time of processing">
                                                        <b-form-input v-model="tenant.statistics.accounts.enabled" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Disabled" label-align-sm="right" label-cols-sm="3" description="This is the number of disabled accounts at the time of processing.">
                                                        <b-form-input v-model="tenant.statistics.accounts.disabled" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Locked" label-align-sm="right" label-cols-sm="3" description="This is the number of locked accounts at the time of processing.">
                                                        <b-form-input v-model="tenant.statistics.accounts.locked" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-card>
                                </b-col>
                                <b-col xl="6" xxl="4" class="mb-4">
                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                        <b-card-header class="bg-light d-flex">
                                            <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                            <h5 class="text-secondary mb-0 mr-2">Logins</h5>
                                        </b-card-header>
                                        <b-card-body>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Browser" label-align-sm="right" label-cols-sm="3" description="This is the number of browser logins, i.e. with an OAuth 2.0 Authorization Code grant, within the current month.">
                                                        <b-form-input v-model="tenant.statistics.logins.browser" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Client" label-align-sm="right" label-cols-sm="3" description="This is the number of client logins, i.e. with an OAuth 2.0 Client Credentials grant, within the current month.">
                                                        <b-form-input v-model="tenant.statistics.logins.client" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Refresh" label-align-sm="right" label-cols-sm="3" description="This is the number of refresh logins, i.e. with an OAuth 2.0 Refresh Token grant, within the current month.">
                                                        <b-form-input v-model="tenant.statistics.logins.refresh" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Native" label-align-sm="right" label-cols-sm="3" description="This is the number of native logins, i.e. with an OAuth 2.0 JWT Bearer grant, within the current month.">
                                                        <b-form-input v-model="tenant.statistics.logins.native" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-card>
                                </b-col>
                                <b-col xl="6" xxl="4" class="mb-4">
                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                        <b-card-header class="bg-light d-flex">
                                            <b-img src="/img/icons/code.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                            <h5 class="text-secondary mb-0 mr-2">API Calls</h5>
                                        </b-card-header>
                                        <b-card-body>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Pending" label-align-sm="right" label-cols-sm="3" description="This is the number of pending API calls, i.e. redirects that don't complete a request, within the current month.">
                                                        <b-form-input v-model="tenant.statistics.api_calls.pending" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Success" label-align-sm="right" label-cols-sm="3" description="This is the number of successful API calls within the current month.">
                                                        <b-form-input v-model="tenant.statistics.api_calls.success" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-group label="Failed" label-align-sm="right" label-cols-sm="3" description="This is the number of failed API calls within the current month.">
                                                        <b-form-input v-model="tenant.statistics.api_calls.failed" readonly></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer v-if="tenant.statistics.updated_at" class="text-muted bg-light">
                            <small>Last updated at {{ tenant.statistics.updated_at.toLocaleString() }}</small>
                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="tenant?.config">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                        <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
                    </b-card-header>
                    <b-collapse id="configuration" visible>
                        <b-card-body v-if="tenant.config.interfaces">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'interfaces')">
                                    <b-img src="/img/icons/interface.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Interfaces</h5>
                                </b-card-header>
                                <b-collapse id="interfaces" visible>
                                    <b-card-body>
                                        <b-row>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Color" label-align-sm="right" label-cols-sm="3">
                                                    <b-form-input v-model="tenant.config.interfaces.color" type="color" disabled></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="8">
                                                <b-form-group label="Logo" label-align-sm="right" label-cols-sm="3" label-cols-xxl="2">
                                                    <b-input-group>
                                                        <b-form-input v-model="tenant.config.interfaces.logo" readonly></b-form-input>
                                                        <b-input-group-append>
                                                            <b-button :href="tenant.config.interfaces.logo" target="_blank" variant="outline-primary">Open</b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                    <b-card-body class="pb-0">
                                        <b-row>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Login</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Options" label-align-sm="right" label-cols-sm="3" description="These are the options for the Login UI.">
                                                                    <b-form-checkbox-group v-model="tenant.config.interfaces.login.options" :options="login_options" stacked switches disabled></b-form-checkbox-group>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Account</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Client" label-align-sm="right" label-cols-sm="3" description="This is the client ID for the Account UI.">
                                                                    <b-form-input v-model="tenant.config.interfaces.account.client" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/tenant.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Admin</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Client" label-align-sm="right" label-cols-sm="3" description="This is the client ID for the Admin UI.">
                                                                    <b-form-input v-model="tenant.config.interfaces.admin.client" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                        <b-card-body v-if="tenant.config.tokens">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'tokens')">
                                    <b-img src="/img/icons/token.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Tokens</h5>
                                </b-card-header>
                                <b-collapse id="tokens" visible>
                                    <b-card-body class="pb-0">
                                        <b-row>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Login</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a login token is valid, i.e. how long users can stay logged in.">
                                                                    <b-form-input v-model="tenant.config.tokens.login.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                    <b-form-select v-model="tenant.config.tokens.login.use" :options="usages" disabled></b-form-select>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/account-pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Signup</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a signup token is valid, i.e. how long users have to sign up.">
                                                                    <b-form-input v-model="tenant.config.tokens.signup.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                    <b-form-select v-model="tenant.config.tokens.signup.use" :options="usages" disabled></b-form-select>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/consent.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Consent</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a consent token is valid, i.e. how fast the login process must progress after consent.">
                                                                    <b-form-input v-model="tenant.config.tokens.consent.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                    <b-form-select v-model="tenant.config.tokens.consent.use" :options="usages" disabled></b-form-select>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                        <b-card-body v-if="tenant.config.accounts">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'accounts')">
                                    <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Accounts</h5>
                                </b-card-header>
                                <b-collapse id="accounts" visible>
                                    <b-card-body class="pb-0">
                                        <b-row>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Pending</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before a pending account is deleted.">
                                                                    <b-form-input v-model="tenant.config.accounts.pending.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/enabled.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Enabled</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before an inactive account is deleted.">
                                                                    <b-form-input v-model="tenant.config.accounts.enabled.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                        <b-card-body v-if="tenant.config.extensions">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'extensions')">
                                    <b-img src="/img/icons/code.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Extensions</h5>
                                </b-card-header>
                                <b-collapse id="extensions" visible>
                                    <b-card-body class="pb-0">
                                        <b-row>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Pending</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before a pending extension is deleted.">
                                                                    <b-form-input v-model="tenant.config.extensions.pending.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                            <b-col xl="6" xxl="4" class="mb-4">
                                                <b-card class="shadow rounded bg-white" no-body fluid>
                                                    <b-card-header class="bg-light d-flex">
                                                        <b-img src="/img/icons/enabled.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                        <h5 class="text-secondary mb-0 mr-2">Enabled</h5>
                                                    </b-card-header>
                                                    <b-card-body>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before an inactive extension is deleted">
                                                                    <b-form-input v-model="tenant.config.extensions.enabled.exp" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col>
                                                                <b-form-group label="Maximum" label-align-sm="right" label-cols-sm="3" description="This is the maximum amount of extensions.">
                                                                    <b-form-input v-model="tenant.config.extensions.enabled.max" type="number" readonly></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-card-body>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="tenant">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="tenant.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="tenant.created_by">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="tenant.created_by" readonly></b-form-input>
                                <b-badge v-if="tenant.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${tenant.created_by}`" variant="outline-primary" :disabled="tenant.created_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="tenant.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="tenant.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="tenant.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="tenant.updated_by" readonly></b-form-input>
                                <b-badge v-if="tenant.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${tenant.updated_by}`" variant="outline-primary" :disabled="tenant.updated_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="tenant.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="tenant.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" class="ml-2" v-on:click="getTenant()">Refresh</b-button>
            </b-card-body>
            <b-card-footer v-if="tenant?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ tenant.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const USAGES = [
    { value: 0, text: 'Unlimited' },
    { value: 1, text: 'One-Time' }
];
const LOGIN_OPTIONS = [
    { value: 'highlight_identity_providers', text: 'Highlight Identity Providers' },
    { value: 'highlight_one_step_options', text: 'Highlight 1-Step Options' },
    { value: 'show_scores', text: 'Show Scores' },
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'AccountTenant',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // TENANT
            tenant: undefined,
            // STATUSES
            statuses: STATUSES,
            // USAGES
            usages: USAGES,
            // LOGIN OPTIONS
            login_options: LOGIN_OPTIONS
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.tenant) {
                this.getTenant();
            }
        },

        /**
         * TENANT
         */
        async getTenant() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getTenant($id: ID!) {
                                getTenant(id: $id) {
                                    id
                                    label
                                    status
                                    account
                                    subscription
                                    metrics {
                                        maa
                                        mac
                                        updated_at
                                    }
                                    statistics {
                                        accounts {
                                            pending
                                            enabled
                                            disabled
                                            locked
                                        }
                                        api_calls {
                                            pending
                                            success
                                            failed
                                        }
                                        logins {
                                            browser
                                            native
                                            client
                                            refresh
                                        }
                                        signups {
                                            pending
                                            success
                                            failed
                                        }
                                        updated_at
                                    }
                                    config {
                                        tokens {
                                            login {
                                                exp
                                                use
                                            }
                                            signup {
                                                exp
                                                use
                                            }
                                            consent {
                                                exp
                                                use
                                            }
                                        }
                                        accounts {
                                            pending {
                                                exp
                                            }
                                            enabled {
                                                exp
                                            }
                                        }
                                        extensions {
                                            pending {
                                                exp
                                            }
                                            enabled {
                                                exp
                                                max
                                            }
                                        }
                                        interfaces {
                                            color
                                            logo
                                            login {
                                                options
                                            }
                                            account {
                                                client
                                            }
                                            admin {
                                                client
                                            }
                                        }
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.tenant = (await response.json()).data.getTenant;
                    // SET DATES
                    this.tenant.metrics.updated_at = this.tenant.metrics.updated_at ? new Date(this.tenant.metrics.updated_at) : undefined;
                    this.tenant.statistics.updated_at = this.tenant.statistics.updated_at ? new Date(this.tenant.statistics.updated_at) : undefined;
                    this.tenant.created_at = this.tenant.created_at ? new Date(this.tenant.created_at) : undefined;
                    this.tenant.updated_at = this.tenant.updated_at ? new Date(this.tenant.updated_at) : undefined;
                    this.tenant.expires_at = this.tenant.expires_at ? new Date(this.tenant.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.tenant.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tenant.', 'Tenant', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tenant.', 'Tenant', 'danger');
            }
            this.loading = undefined;
        }
    }
}
</script>