<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/controls.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Rule</h3>
            </b-card-header>
            <b-card-body v-if="rule">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="rule.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="rule.account" readonly></b-form-input>
                                <b-badge v-if="rule.account === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${rule.account}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" :state="validField('label')" invalid-feedback="Please provide a valid label.">
                            <b-form-input v-model="rule.label" :state="validField('label')" :readonly="!canEdit()"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="$store.state.controls?.items">
                        <b-form-group label="Control" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-select v-model="rule.control" :options="$store.state.controls.items" value-field="id" text-field="label" disabled></b-form-select>
                                <b-input-group-append>
                                    <b-button :to="`/controls/${rule.control}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="rule.status" :options="canEdit() ? statuses.filter(status => ['ENABLED','DISABLED'].includes(status.value)) : statuses" :disabled="!canEdit()"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Required" label-align-sm="right" label-cols-sm="3" description="This indicates whether the control must be passed.">
                            <b-form-checkbox v-model="rule.required" switch :disabled="!canEdit()"></b-form-checkbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="rule">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="rule.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="rule.created_by" readonly></b-form-input>
                                <b-badge v-if="rule.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${rule.created_by}`" variant="outline-primary" :disabled="rule.created_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="rule.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="rule.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="rule.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="rule.updated_by" readonly></b-form-input>
                                <b-badge v-if="rule.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${rule.updated_by}`" variant="outline-primary" :disabled="rule.updated_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="rule.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="rule.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="success" v-on:click="saveRule()" :disabled="!canEdit() || !validRule()">Save</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="getRule()">Refresh</b-button>
                <b-button variant="outline-danger" class="ml-auto" v-on:click="$emit('show', 'delete-rule', rule)" :disabled="!canEdit()">Delete</b-button>
            </b-card-body>
            <b-card-footer v-if="rule?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ rule.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Rule',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // RULE
            rule: undefined,
            // STATUSES
            statuses: STATUSES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.rule) {
                    this.getRule();
                }
                if (!this.$store.state.controls) {
                    this.$emit('load', 'controls', true); // LOAD ALL
                } else if (this.$store.state.controls.nextToken) {
                    this.$emit('next', 'controls', this.$store.state.controls.nextToken, true); // LOAD ALL
                }
            }
        },

        /**
         * RULE
         */
        async getRule() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getRule($id: ID!) {
                                getRule(id: $id) {
                                    id
                                    label
                                    control
                                    account
                                    status
                                    required
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.rule = (await response.json()).data.getRule;
                    // SET DATES
                    this.rule.created_at = this.rule.created_at ? new Date(this.rule.created_at) : undefined;
                    this.rule.updated_at = this.rule.updated_at ? new Date(this.rule.updated_at) : undefined;
                    this.rule.expires_at = this.rule.expires_at ? new Date(this.rule.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.rule.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain rule.', 'Rule', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain rule.', 'Rule', 'danger');
            }
            this.loading = undefined;
        },

        async saveRule() {
            this.loading = 'Saving';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateRule($input: UpdateRuleInput!) {
                                updateRule(input: $input) {
                                    id
                                    label
                                    control
                                    account
                                    status
                                    required
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.rule.id}",
                                "label": "${this.rule.label}",
                                "status": "${this.rule.status}",
                                "required": ${this.rule.required}
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.rule = (await response.json()).data.updateRule;
                    // SET DATES
                    this.rule.created_at = this.rule.created_at ? new Date(this.rule.created_at) : undefined;
                    this.rule.updated_at = this.rule.updated_at ? new Date(this.rule.updated_at) : undefined;
                    this.rule.expires_at = this.rule.expires_at ? new Date(this.rule.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.rule.refreshed_at = new Date();
                    this.$emit('alert', 'Your rule has been updated', 'Rule', 'success', 5000);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save rule.', 'Rule', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save rule.', 'Rule', 'danger');
            }
            this.loading = undefined;
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED','DISABLED'].includes(this.rule?.status);
        },
        
        validRule() {
            if (!this.validField('label')) return false;
            return true;
        },

        validField(field) {
            switch (field) {
                case 'label':
                    return !!this.rule.label;
                default:
                    return false;
            }
        }
    }
}
</script>