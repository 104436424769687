<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/claims/${$route.params.id}`" exact exact-active-class="active">Attribute</b-nav-item>
            <b-nav-item :to="`/claims/${$route.params.id}/factors`" exact exact-active-class="active">Factors</b-nav-item>
        </b-nav>
    </b-card-header>
    <b-card-body v-if="claim">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                    <b-form-input v-model="claim.id" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="claim.account" readonly></b-form-input>
                        <b-badge v-if="claim.account === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${claim.account}`" variant="outline-primary">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Label" label-align-sm="right" label-cols-sm="3">
                    <b-form-input v-model="claim.label" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3">
                    <b-form-select v-model="claim.subtype" :options="subtypes" disabled></b-form-select>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="$store.state.attributes?.items">
                <b-form-group label="Attribute" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-select v-model="claim.attribute" :options="$store.state.attributes.items" value-field="id" text-field="label" disabled></b-form-select>
                        <b-input-group-append>
                            <b-button :to="`/attributes/${claim.attribute}`" variant="outline-primary">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                    <b-form-select v-model="claim.status" :options="statuses" disabled></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body v-if="claim?.value">
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'value')">
                <b-img src="/img/icons/detail.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h5 class="text-secondary mb-0 mr-2">Value<b-badge class="ml-2" variant="danger">Data</b-badge></h5>
            </b-card-header>
            <b-collapse id="value">
                <b-card-body>
                    <b-row>
                        <b-col xl="12">
                            <b-form-group>
                                <b-form-textarea :value="claim.subtype.startsWith('json') ? JSON.parse(claim.value) : claim.value" rows="5" readonly></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </b-card>
    </b-card-body>
    <b-card-body v-if="claim">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="claim.created_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="claim.created_by" readonly></b-form-input>
                        <b-badge v-if="claim.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${claim.created_by}`" variant="outline-primary" :disabled="claim.created_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="claim.updated_at">
                <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="claim.updated_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="claim.updated_by">
                <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="claim.updated_by" readonly></b-form-input>
                        <b-badge v-if="claim.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${claim.updated_by}`" variant="outline-primary" :disabled="claim.updated_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="claim.expires_at">
                <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="claim.expires_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="$emit('load', 'claim')">Refresh</b-button>
        <b-button v-if="claim?.status === 'ENABLED'" variant="outline-danger" class="ml-auto" v-on:click="disableClaim()">Disable</b-button>
        <b-button :class="claim?.status === 'ENABLED' ? 'ml-2' : 'ml-auto'" variant="outline-danger" v-on:click="$emit('show', 'delete-claim', claim)" :disabled="!canDelete()">Delete</b-button>
    </b-card-body>
    <b-card-footer v-if="claim?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ claim.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const SUBTYPES = [
    { value: 'string', text: 'String' },
    { value: 'number', text: 'Number' },
    { value: 'boolean', text: 'Boolean' },
    { value: 'url', text: 'URL' },
    { value: 'json', text: 'JSON' },
    { value: 'string:oidc1:name', text: 'Name' },
    { value: 'string:oidc1:given_name', text: 'Given Name' },
    { value: 'string:oidc1:family_name', text: 'Family Name' },
    { value: 'string:oidc1:middle_name', text: 'Middle Name' },
    { value: 'string:oidc1:nickname', text: 'Nickname' },
    { value: 'string:oidc1:preferred_username', text: 'Preferred Username' },
    { value: 'url:oidc1:profile', text: 'Profile' },
    { value: 'url:oidc1:picture', text: 'Picture' },
    { value: 'url:oidc1:website', text: 'Website' },
    { value: 'string:oidc1:email', text: 'Email' },
    { value: 'string:oidc1:gender', text: 'Gender' },
    { value: 'string:oidc1:birthdate', text: 'Birthdate' },
    { value: 'string:oidc1:zoneinfo', text: 'Time Zone' },
    { value: 'string:oidc1:locale', text: 'Locale' },
    { value: 'string:oidc1:phone_number', text: 'Phone Number' },
    { value: 'string:oidc1:address:formatted', text: 'Formatted Address' },
    { value: 'string:oidc1:address:street_address', text: 'Street Address' },
    { value: 'string:oidc1:address:locality', text: 'Locality' },
    { value: 'string:oidc1:address:region', text: 'Region' },
    { value: 'string:oidc1:address:postal_code', text: 'Postal Code' },
    { value: 'string:oidc1:address:country', text: 'Country' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Claim',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'loading', 'load', 'next', 'show', 'disable' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        claim: Object
    },

    /**
     * DATA
     */
    data() {
        return {
            // STATUSES
            statuses: STATUSES,
            // SUBTYPES
            subtypes: SUBTYPES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
        * INITIALIZE
        */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.$store.state.attributes) {
                    this.$emit('load', 'attributes', true); // LOAD ALL
                } else if (this.$store.state.attributes.nextToken) {
                    this.$emit('next', 'attributes', this.$store.state.attributes.nextToken, true); // LOAD ALL
                }
            }
        },

        /**
         * CLAIM
         */
        async disableClaim() {
            this.$emit('loading', 'Disabling');
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateClaim($input: UpdateClaimInput!) {
                                updateClaim(input: $input) {
                                    id
                                    label
                                    attribute
                                    account
                                    value
                                    status
                                    subtype
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.claim.id}",
                                "status": "DISABLED"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const claim = (await response.json()).data.updateClaim;
                    this.$emit('load', 'claim');
                    this.$emit('alert', 'The attribute has been disabled.', 'Attribute', 'success', 5000);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to disable attribute.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to disable attribute.', 'Attribute', 'danger');
            }
            this.$emit('loading', undefined);
        },

        /**
         * VALIDATION
         */
        canDelete() {
            return this.claim?.status !== 'LOCKED';
        }
    }
}
</script>