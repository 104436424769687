<template>
    <b-overlay :show="loading || loading2">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading || loading2 }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/tenant.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Tenant</h3>
            </b-card-header>
            <div v-if="getTenant()">
                <b-card-body>
                    <b-row>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                                <b-form-input v-model="tenant.id" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" :state="validField('label')" invalid-feedback="Please provide a valid label." description="Please note this label is visible to our administrators.">
                                <b-form-input v-model="tenant.label" :state="validField('label')" :readonly="!canEdit()"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                                <b-form-select v-model="tenant.status" :options="canEdit() ? statuses.filter(status => ['ENABLED','DISABLED'].includes(status.value)) : statuses" :disabled="!canEdit()"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                                <b-form-input v-model="tenant.account" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Subscription" label-align-sm="right" label-cols-sm="3">
                                <b-form-input v-model="tenant.subscription" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-body v-if="tenant.metrics">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'metrics')">
                            <b-img src="/img/icons/metrics.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Metrics</h5>
                        </b-card-header>
                        <b-collapse id="metrics" visible>
                            <b-card-header class="text-muted bg-light">
                                <small>Metrics are currently processed every 5 minutes.</small>
                            </b-card-header>
                            <b-card-body>
                                <b-row>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="MAA" label-align-sm="right" label-cols-sm="3" description="These are the Monthly Active Accounts (MAA) for this tenant within the current month.">
                                            <b-form-input v-model="tenant.metrics.maa" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="MAC" label-align-sm="right" label-cols-sm="3" description="These are the Monthly API Calls (MAC) for this tenant within the current month.">
                                            <b-form-input v-model="tenant.metrics.mac" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-card-footer v-if="tenant.metrics.updated_at" class="text-muted bg-light">
                                <small>Last updated at {{ tenant.metrics.updated_at.toLocaleString() }}</small>
                            </b-card-footer>
                        </b-collapse>
                    </b-card>
                </b-card-body>
                <b-card-body v-if="tenant.statistics">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'statistics')">
                            <b-img src="/img/icons/statistics.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Statistics</h5>
                        </b-card-header>
                        <b-collapse id="statistics" visible>
                            <b-card-header class="text-muted bg-light">
                                <small>Statistics are currently processed every day at 4 AM UTC.</small>
                            </b-card-header>
                            <b-card-body class="pb-0">
                                <b-row>
                                    <b-col xl="6" xxl="4" class="mb-4">
                                        <b-card class="shadow rounded bg-white" no-body fluid>
                                            <b-card-header class="bg-light d-flex">
                                                <b-img src="/img/icons/account-pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                <h5 class="text-secondary mb-0 mr-2">Signups</h5>
                                            </b-card-header>
                                            <b-card-body>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Pending" label-align-sm="right" label-cols-sm="3" description="This is the number of started signups, i.e. pending accounts that got created, within the current month.">
                                                            <b-form-input v-model="tenant.statistics.signups.pending" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Success" label-align-sm="right" label-cols-sm="3" description="This is the number of successful signups, i.e. pending accounts that got enabled, within the current month.">
                                                            <b-form-input v-model="tenant.statistics.signups.success" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Failed" label-align-sm="right" label-cols-sm="3" description="This is the number of failed signups, i.e. pending accounts that expired, within the current month.">
                                                            <b-form-input v-model="tenant.statistics.signups.failed" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col xl="6" xxl="4" class="mb-4">
                                        <b-card class="shadow rounded bg-white" no-body fluid>
                                            <b-card-header class="bg-light d-flex">
                                                <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                <h5 class="text-secondary mb-0 mr-2">Accounts</h5>
                                            </b-card-header>
                                            <b-card-body>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Pending" label-align-sm="right" label-cols-sm="3" description="This is the number of pending accounts, i.e. accounts that have not yet completed signup, at the time of processing.">
                                                            <b-form-input v-model="tenant.statistics.accounts.pending" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Enabled" label-align-sm="right" label-cols-sm="3" description="This is the number of enabled accounts at the time of processing">
                                                            <b-form-input v-model="tenant.statistics.accounts.enabled" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Disabled" label-align-sm="right" label-cols-sm="3" description="This is the number of disabled accounts at the time of processing.">
                                                            <b-form-input v-model="tenant.statistics.accounts.disabled" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Locked" label-align-sm="right" label-cols-sm="3" description="This is the number of locked accounts at the time of processing.">
                                                            <b-form-input v-model="tenant.statistics.accounts.locked" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col xl="6" xxl="4" class="mb-4">
                                        <b-card class="shadow rounded bg-white" no-body fluid>
                                            <b-card-header class="bg-light d-flex">
                                                <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                <h5 class="text-secondary mb-0 mr-2">Logins</h5>
                                            </b-card-header>
                                            <b-card-body>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Browser" label-align-sm="right" label-cols-sm="3" description="This is the number of browser logins, i.e. with an OAuth 2.0 Authorization Code grant, within the current month.">
                                                            <b-form-input v-model="tenant.statistics.logins.browser" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Client" label-align-sm="right" label-cols-sm="3" description="This is the number of client logins, i.e. with an OAuth 2.0 Client Credentials grant, within the current month.">
                                                            <b-form-input v-model="tenant.statistics.logins.client" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Refresh" label-align-sm="right" label-cols-sm="3" description="This is the number of refresh logins, i.e. with an OAuth 2.0 Refresh Token grant, within the current month.">
                                                            <b-form-input v-model="tenant.statistics.logins.refresh" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Native" label-align-sm="right" label-cols-sm="3" description="This is the number of native logins, i.e. with an OAuth 2.0 JWT Bearer grant, within the current month.">
                                                            <b-form-input v-model="tenant.statistics.logins.native" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col xl="6" xxl="4" class="mb-4">
                                        <b-card class="shadow rounded bg-white" no-body fluid>
                                            <b-card-header class="bg-light d-flex">
                                                <b-img src="/img/icons/code.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                <h5 class="text-secondary mb-0 mr-2">API Calls</h5>
                                            </b-card-header>
                                            <b-card-body>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Pending" label-align-sm="right" label-cols-sm="3" description="This is the number of pending API calls, i.e. redirects that don't complete a request, within the current month.">
                                                            <b-form-input v-model="tenant.statistics.api_calls.pending" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Success" label-align-sm="right" label-cols-sm="3" description="This is the number of successful API calls within the current month.">
                                                            <b-form-input v-model="tenant.statistics.api_calls.success" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-group label="Failed" label-align-sm="right" label-cols-sm="3" description="This is the number of failed API calls within the current month.">
                                                            <b-form-input v-model="tenant.statistics.api_calls.failed" readonly></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-card-footer v-if="tenant.statistics.updated_at" class="text-muted bg-light">
                                <small>Last updated at {{ tenant.statistics.updated_at.toLocaleString() }}</small>
                            </b-card-footer>
                        </b-collapse>
                    </b-card>
                </b-card-body>
                <b-card-body v-if="tenant.config">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                            <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
                        </b-card-header>
                        <b-collapse id="configuration" visible>
                            <b-card-body v-if="tenant.config.interfaces">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'interfaces')">
                                        <b-img src="/img/icons/interface.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2">Interfaces</h5>
                                    </b-card-header>
                                    <b-collapse id="interfaces" visible>
                                        <b-card-body>
                                            <b-row>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="Color" label-align-sm="right" label-cols-sm="3">
                                                        <b-form-input v-model="tenant.config.interfaces.color" type="color" :disabled="!canEdit()"></b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="8">
                                                    <b-form-group label="Logo" label-align-sm="right" label-cols-sm="3" label-cols-xxl="2" :state="tenant.config.interfaces.new_logo ? validField('config.interfaces.new_logo') : undefined" invalid-feedback="Please upload a valid logo. Note we only accept JPEG and PNG formats and the logo can be maximum 440x440 pixels.">
                                                        <b-input-group v-if="tenant.config.interfaces.new_logo">
                                                            <b-form-file v-model="tenant.config.interfaces.new_logo" accept=".jpg, .jpeg, .png" :state="validField('config.interfaces.new_logo')" :disabled="!canEdit()"></b-form-file>
                                                            <b-input-group-append>
                                                                <b-button v-on:click="delete tenant.config.interfaces.new_logo" variant="outline-danger" :disabled="!tenant.config.interfaces.new_logo">Abort</b-button>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                        <b-input-group v-else>
                                                            <b-form-input v-model="tenant.config.interfaces.logo" readonly></b-form-input>
                                                            <b-input-group-append>
                                                                <b-button :href="tenant.config.interfaces.logo" target="_blank" variant="outline-primary">Open</b-button>
                                                                <b-button v-on:click="tenant.config.interfaces.new_logo = {}" variant="outline-primary" :disabled="!canEdit()">Upload</b-button>
                                                                <b-button v-on:click="tenant.config.interfaces.logo = `https://login${$store.state.domain}/img/logos/b62a482d-7365-4ae9-85a5-1453b3b0d5b7.png`" variant="outline-danger" :disabled="!canEdit()">Reset</b-button>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                        <b-card-body class="pb-0">
                                            <b-row>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Login</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Options" label-align-sm="right" label-cols-sm="3" description="These are the options for the Login UI.">
                                                                        <b-form-checkbox-group v-model="tenant.config.interfaces.login.options" :options="login_options" stacked switches :disabled="!canEdit()"></b-form-checkbox-group>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Account</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Client" label-align-sm="right" label-cols-sm="3" description="This is the client ID for the Account UI.">
                                                                        <b-input-group>
                                                                            <b-form-input v-model="tenant.config.interfaces.account.client" readonly></b-form-input>
                                                                            <b-input-group-append>
                                                                                <b-button :to="`/accounts/${tenant.config.interfaces.account.client}`" variant="outline-primary">Open</b-button>
                                                                            </b-input-group-append>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/tenant.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Admin</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Client" label-align-sm="right" label-cols-sm="3" description="This is the client ID for the Admin UI.">
                                                                        <b-input-group>
                                                                            <b-form-input v-model="tenant.config.interfaces.admin.client" readonly></b-form-input>
                                                                            <b-input-group-append>
                                                                                <b-button :to="`/accounts/${tenant.config.interfaces.admin.client}`" variant="outline-primary">Open</b-button>
                                                                            </b-input-group-append>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-card-body>
                            <b-card-body v-if="tenant.config.tokens">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'tokens')">
                                        <b-img src="/img/icons/token.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2">Tokens</h5>
                                    </b-card-header>
                                    <b-collapse id="tokens" visible>
                                        <b-card-body class="pb-0">
                                            <b-row>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/login.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Login</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a login token is valid, i.e. how long users can stay logged in." :state="validField('config.tokens.login.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 1 year.">
                                                                        <b-form-input v-model="tenant.config.tokens.login.exp" :state="validField('config.tokens.login.exp')" :readonly="!canEdit()"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                        <b-form-select v-model="tenant.config.tokens.login.use" :options="usages" disabled></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/account-pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Signup</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a signup token is valid, i.e. how long users have to sign up." :state="validField('config.tokens.signup.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 1 hour.">
                                                                        <b-form-input v-model="tenant.config.tokens.signup.exp" :state="validField('config.tokens.signup.exp')" :readonly="!canEdit()"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                        <b-form-select v-model="tenant.config.tokens.signup.use" :options="usages" disabled></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/consent.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Consent</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a consent token is valid, i.e. how fast the login process must progress after consent.">
                                                                        <b-form-input v-model="tenant.config.tokens.consent.exp" readonly></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                        <b-form-select v-model="tenant.config.tokens.consent.use" :options="usages" disabled></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-card-body>
                            <b-card-body v-if="tenant.config.accounts">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'accounts')">
                                        <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2">Accounts</h5>
                                    </b-card-header>
                                    <b-collapse id="accounts" visible>
                                        <b-card-body class="pb-0">
                                            <b-row>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Pending</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before a pending account is deleted." :state="validField('config.accounts.pending.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 30 days.">
                                                                        <b-form-input v-model="tenant.config.accounts.pending.exp" :state="validField('config.accounts.pending.exp')" :readonly="!canEdit()"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/enabled.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Enabled</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before an inactive account is deleted." :state="validField('config.accounts.enabled.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 400 days.">
                                                                        <b-form-input v-model="tenant.config.accounts.enabled.exp" :state="validField('config.accounts.enabled.exp')" :readonly="!canEdit()"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-card-body>
                            <b-card-body v-if="tenant.config.extensions">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'extensions')">
                                        <b-img src="/img/icons/code.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2">Extensions</h5>
                                    </b-card-header>
                                    <b-collapse id="extensions" visible>
                                        <b-card-body class="pb-0">
                                            <b-row>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/pending.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Pending</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before a pending extension is deleted.">
                                                                        <b-form-input v-model="tenant.config.extensions.pending.exp" readonly></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                                <b-col xl="6" xxl="4" class="mb-4">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/enabled.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Enabled</h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long before an inactive extension is deleted">
                                                                        <b-form-input v-model="tenant.config.extensions.enabled.exp" readonly></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Maximum" label-align-sm="right" label-cols-sm="3" description="This is the maximum amount of extensions.">
                                                                        <b-form-input v-model="tenant.config.extensions.enabled.max" type="number" readonly></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-card-body>
                <b-card-body>
                    <b-row>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                                <b-form-input :value="tenant.created_at.toLocaleString()" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                                <b-form-input v-model="tenant.created_by" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4" v-if="tenant.updated_at">
                            <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                                <b-form-input :value="tenant.updated_at.toLocaleString()" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4" v-if="tenant.updated_by">
                            <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                                <b-input-group>
                                    <b-form-input v-model="tenant.updated_by" readonly></b-form-input>
                                    <b-badge v-if="tenant.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                    <b-input-group-append>
                                        <b-button :to="`/accounts/${tenant.updated_by}`" variant="outline-primary" :disabled="tenant.updated_by === 'SYSTEM'">Open</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4" v-if="tenant.expires_at">
                            <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                                <b-form-input :value="tenant.expires_at.toLocaleString()" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-body class="d-flex">
                    <b-button variant="success" v-on:click="saveTenant()" :disabled="!canEdit() || !validTenant()">Save</b-button>
                    <b-button variant="outline-primary" class="ml-2" v-on:click="refreshTenant()">Refresh</b-button>
                    <b-button variant="outline-danger" class="ml-auto" v-on:click="resetTenant()">Reset</b-button>
                </b-card-body>
                <b-card-footer v-if="tenant.refreshed_at" class="text-muted bg-light">
                    <small>Last refreshed at {{ tenant.refreshed_at.toLocaleString() }}</small>
                </b-card-footer>
            </div>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const USAGES = [
    { value: 0, text: 'Unlimited' },
    { value: 1, text: 'One-Time' }
];
const TIME_REGEX = new RegExp(/^(\d+|\d+\.\d+) ?(seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|weeks?|w|years?|yrs?|y)$/i);
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const YEAR = DAY * 365.25;
const LOGIN_OPTIONS = [
    { value: 'highlight_identity_providers', text: 'Highlight Identity Providers' },
    { value: 'highlight_one_step_options', text: 'Highlight 1-Step Options' },
    { value: 'show_scores', text: 'Show Scores' },
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Tenant',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load' ],

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // TENANT
            tenant: undefined,
            // STATUSES
            statuses: STATUSES,
            // USAGES
            usages: USAGES,
            // LOADING
            loading2: undefined,
            // LOGIN OPTIONS
            login_options: LOGIN_OPTIONS
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                this.getTenant(true);
            }
        },

        /**
         * TENANT
         */
        getTenant(reset) {
            if (!this.$store.state.tenant) {
                this.$emit('load', 'tenant');
                return false;
            } else if (!this.tenant || reset) {
                this.resetTenant();
            }
            return true;
        },

        refreshTenant() {
            this.$emit('load', 'tenant');
            setTimeout(this.resetTenant, 2000);
        },

        resetTenant() {
            this.tenant = {
                id: this.$store.state.tenant.id,
                label: this.$store.state.tenant.label,
                status: this.$store.state.tenant.status,
                account: this.$store.state.tenant.account,
                subscription: this.$store.state.tenant.subscription,
                metrics: {
                    maa: this.$store.state.tenant.metrics.maa,
                    mac: this.$store.state.tenant.metrics.mac,
                    updated_at: this.$store.state.tenant.metrics.updated_at ? new Date(this.$store.state.tenant.metrics.updated_at) : undefined,
                },
                statistics: {
                    accounts: {
                        pending: this.$store.state.tenant.statistics.accounts.pending,
                        enabled: this.$store.state.tenant.statistics.accounts.enabled,
                        disabled: this.$store.state.tenant.statistics.accounts.disabled,
                        locked: this.$store.state.tenant.statistics.accounts.locked
                    },
                    api_calls: {
                        pending: this.$store.state.tenant.statistics.api_calls.pending,
                        success: this.$store.state.tenant.statistics.api_calls.success,
                        failed: this.$store.state.tenant.statistics.api_calls.failed
                    },
                    logins: {
                        browser: this.$store.state.tenant.statistics.logins.browser,
                        native: this.$store.state.tenant.statistics.logins.native,
                        client: this.$store.state.tenant.statistics.logins.client,
                        refresh: this.$store.state.tenant.statistics.logins.refresh
                    },
                    signups: {
                        pending: this.$store.state.tenant.statistics.signups.pending,
                        success: this.$store.state.tenant.statistics.signups.success,
                        failed: this.$store.state.tenant.statistics.signups.failed
                    },
                    updated_at: this.$store.state.tenant.statistics.updated_at ? new Date(this.$store.state.tenant.statistics.updated_at) : undefined,
                },
                config: {
                    tokens: {
                        login: {
                            exp: this.$store.state.tenant.config.tokens.login.exp,
                            use: this.$store.state.tenant.config.tokens.login.use
                        },
                        signup: {
                            exp: this.$store.state.tenant.config.tokens.signup.exp,
                            use: this.$store.state.tenant.config.tokens.signup.use
                        },
                        consent: {
                            exp: this.$store.state.tenant.config.tokens.consent.exp,
                            use: this.$store.state.tenant.config.tokens.consent.use
                        }
                    },
                    accounts: {
                        pending: {
                            exp: this.$store.state.tenant.config.accounts.pending.exp
                        },
                        enabled: {
                            exp: this.$store.state.tenant.config.accounts.enabled.exp
                        }
                    },
                    extensions: {
                        pending: {
                            exp: this.$store.state.tenant.config.extensions.pending.exp
                        },
                        enabled: {
                            exp: this.$store.state.tenant.config.extensions.enabled.exp,
                            max: this.$store.state.tenant.config.extensions.enabled.max
                        }
                    },
                    interfaces: {
                        color: this.$store.state.tenant.config.interfaces.color,
                        logo: this.$store.state.tenant.config.interfaces.logo,
                        login: {
                            options: this.$store.state.tenant.config.interfaces.login.options
                        },
                        account: {
                            client: this.$store.state.tenant.config.interfaces.account.client
                        },
                        admin: {
                            client: this.$store.state.tenant.config.interfaces.admin.client
                        }
                    }
                },
                created_at: this.$store.state.tenant.created_at ? new Date(this.$store.state.tenant.created_at) : undefined,
                created_by: this.$store.state.tenant.created_by,
                updated_at: this.$store.state.tenant.updated_at ? new Date(this.$store.state.tenant.updated_at) : undefined,
                updated_by: this.$store.state.tenant.updated_by,
                expires_at: this.$store.state.tenant.expires_at ? new Date(this.$store.state.tenant.expires_at) : undefined,
                refreshed_at: this.$store.state.tenant.refreshed_at
            };
        },

        async saveTenant() {
            this.loading2 = 'Saving';
            try {

                // PROCESS LOGO
                if (this.tenant.config.interfaces.new_logo) {
                    const reader = new FileReader();
                    reader.onerror = () => {
                        this.$emit('alert', 'Failed to read file.', 'Tenant', 'danger', 5000);
                        this.loading2 = undefined;
                    };
                    reader.onload = async () => {
                        this.tenant.config.interfaces.logo = reader.result;
                        delete this.tenant.config.interfaces.new_logo;
                        await this.saveTenant();
                    };
                    reader.readAsDataURL(this.tenant.config.interfaces.new_logo);
                    return; // KEEP LOADING
                }

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateTenant($input: UpdateTenantInput!) {
                                updateTenant(input: $input) {
                                    id
                                    label
                                    status
                                    account
                                    subscription
                                    metrics {
                                        maa
                                        mac
                                        updated_at
                                    }
                                    statistics {
                                        accounts {
                                            pending
                                            enabled
                                            disabled
                                            locked
                                        }
                                        api_calls {
                                            pending
                                            success
                                            failed
                                        }
                                        logins {
                                            browser
                                            native
                                            client
                                            refresh
                                        }
                                        signups {
                                            pending
                                            success
                                            failed
                                        }
                                        updated_at
                                    }
                                    config {
                                        tokens {
                                            login {
                                                exp
                                                use
                                            }
                                            signup {
                                                exp
                                                use
                                            }
                                            consent {
                                                exp
                                                use
                                            }
                                        }
                                        accounts {
                                            pending {
                                                exp
                                            }
                                            enabled {
                                                exp
                                            }
                                        }
                                        extensions {
                                            pending {
                                                exp
                                            }
                                            enabled {
                                                exp
                                                max
                                            }
                                        }
                                        interfaces {
                                            color
                                            logo
                                            login {
                                                options
                                            }
                                            account {
                                                client
                                            }
                                            admin {
                                                client
                                            }
                                        }
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.tenant.id}",
                                "label": "${this.tenant.label}",
                                "status": "${this.tenant.status}",
                                "config": {
                                    "interfaces": {
                                        "color": "${this.tenant.config.interfaces.color}",
                                        "logo": "${this.tenant.config.interfaces.logo}",
                                        "login": {
                                            "options": ${JSON.stringify(this.tenant.config.interfaces.login.options) /* ARRAY */}
                                        }
                                    },
                                    "tokens": {
                                        "login": {
                                            "exp": "${this.tenant.config.tokens.login.exp}"
                                        },
                                        "signup": {
                                            "exp": "${this.tenant.config.tokens.signup.exp}"
                                        }
                                    },
                                    "accounts": {
                                        "pending": {
                                            "exp": "${this.tenant.config.accounts.pending.exp}"
                                        },
                                        "enabled": {
                                            "exp": "${this.tenant.config.accounts.enabled.exp}"
                                        }
                                    }
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const JSON = (await response.json());
                    // SUCCESS
                    if (JSON.data.updateTenant) {
                        const tenant = JSON.data.updateTenant;
                        this.$emit('alert', 'Your tenant has been updated', 'Tenant', 'success', 5000);
                        // ADD REFRESH DATE
                        tenant.refreshed_at = new Date();
                        this.$store.commit('tenant', tenant);
                        this.resetTenant();
                    // ERROR
                    } else {
                        switch (JSON.errors[0].message) {
                            case 'INVALID_PARAMETER':
                                this.$emit('alert', 'Failed to save tenant because you\'ve provided an invalid parameter. Please consult \'Events\' for more details.', 'Tenant', 'danger');
                                break;
                            default:
                                this.$emit('alert', 'Failed to save tenant.', 'Tenant', 'danger');
                        }
                        // RESET IF NEW LOGO
                        if (!this.tenant.config.interfaces.logo.startsWith('https://')) {
                            this.resetTenant();
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save tenant.', 'Tenant', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save tenant.', 'Tenant', 'danger');
            }
            this.loading2 = undefined;
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED','DISABLED'].includes(this.tenant.status);
        },
        
        validTenant() {
            if (!this.validField('label')) return false;
            if (this.tenant.config.interfaces.new_logo) {
                if (!this.validField('config.interfaces.new_logo')) return false;
            }
            if (!this.validField('config.tokens.login.exp')) return false;
            if (!this.validField('config.tokens.signup.exp')) return false;
            if (!this.validField('config.accounts.pending.exp')) return false;
            if (!this.validField('config.accounts.enabled.exp')) return false;
            return true;
        },

        validField(field) {
            switch (field) {
                case 'label':
                    return !!this.tenant.label;
                case 'config.interfaces.new_logo':
                    return !!this.tenant.config.interfaces.new_logo.name;
                case 'config.tokens.login.exp':
                    if (!TIME_REGEX.test(this.tenant.config.tokens.login.exp)) return false;
                    return this.convert(this.tenant.config.tokens.login.exp) <= this.convert('1y');
                case 'config.tokens.signup.exp':
                    if (!TIME_REGEX.test(this.tenant.config.tokens.signup.exp)) return false;
                    return this.convert(this.tenant.config.tokens.signup.exp) <= this.convert('1h');
                case 'config.accounts.pending.exp':
                    if (!TIME_REGEX.test(this.tenant.config.accounts.pending.exp)) return false;
                    return this.convert(this.tenant.config.accounts.pending.exp) <= this.convert('30d');
                case 'config.accounts.enabled.exp':
                    if (!TIME_REGEX.test(this.tenant.config.accounts.enabled.exp)) return false;
                    return this.convert(this.tenant.config.accounts.enabled.exp) <= this.convert('400d');
                default:
                    return false;
            }
        },

        convert(time) {
            const matched = TIME_REGEX.exec(time);
            const value = parseFloat(matched[1]);
            const unit = matched[2].toLowerCase();
            switch (unit) {
                case 'sec':
                case 'secs':
                case 'second':
                case 'seconds':
                case 's':
                    return Math.round(value);
                case 'minute':
                case 'minutes':
                case 'min':
                case 'mins':
                case 'm':
                    return Math.round(value * MINUTE);
                case 'hour':
                case 'hours':
                case 'hr':
                case 'hrs':
                case 'h':
                    return Math.round(value * HOUR);
                case 'day':
                case 'days':
                case 'd':
                    return Math.round(value * DAY);
                case 'week':
                case 'weeks':
                case 'w':
                    return Math.round(value * WEEK);
                default:
                    return Math.round(value * YEAR);
            }
        }
    }
}
</script>