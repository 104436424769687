<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/icons/source.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Source</h3>
            </b-card-header>
            <b-card-body v-if="link">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="link.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="link.account" readonly></b-form-input>
                                <b-badge v-if="link.account === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${link.account}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Source" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="link.source" readonly></b-form-input>
                                <b-input-group-append>
                                    <b-button :to="`/sources/${link.source}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Attribute" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="link.claim" readonly></b-form-input>
                                <b-input-group-append>
                                    <b-button :to="`/claims/${link.claim}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Factor" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="link.enrollment" readonly></b-form-input>
                                <b-input-group-append>
                                    <b-button :to="`/enrollments/${link.enrollment}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="link.status" :options="statuses" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="link">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="link.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="link.created_by" readonly></b-form-input>
                                <b-badge v-if="link.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${link.created_by}`" variant="outline-primary" :disabled="link.created_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="link.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="link.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="link.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="link.updated_by" readonly></b-form-input>
                                <b-badge v-if="link.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${link.updated_by}`" variant="outline-primary" :disabled="link.updated_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" class="ml-2" v-on:click="getLink()">Refresh</b-button>
            </b-card-body>
            <b-card-footer v-if="link?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ link.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Link',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // LINK
            link: undefined,
            // STATUSES
            statuses: STATUSES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.link) {
                this.getLink();
            }
        },

        /**
         * LINK
         */
        async getLink() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getLink($id: ID!) {
                                getLink(id: $id) {
                                    id
                                    source
                                    claim
                                    enrollment
                                    account
                                    status
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.link = (await response.json()).data.getLink;
                    // SET DATES
                    this.link.created_at = this.link.created_at ? new Date(this.link.created_at) : undefined;
                    this.link.updated_at = this.link.updated_at ? new Date(this.link.updated_at) : undefined;
                    this.link.expires_at = this.link.expires_at ? new Date(this.link.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.link.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain source.', 'Source', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain source.', 'Source', 'danger');
            }
            this.loading = undefined;
        }
    }
}
</script>