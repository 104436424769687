<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/controls/${$route.params.id}`" exact exact-active-class="active">Control</b-nav-item>
            <b-nav-item v-if="control?.config.consent_required" :to="`/controls/${$route.params.id}/consents`" exact exact-active-class="active">Consents</b-nav-item>
            <b-nav-item v-if="control?.config.permission_required" :to="`/controls/${$route.params.id}/permissions`" exact exact-active-class="active">Permissions</b-nav-item>
        </b-nav>
        <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
    </b-card-header>
    <b-card-body v-if="permissions">
        <b-table id="table" outlined hover :items="permissions.items" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/permissions/${item.id}`)" class="mb-0" show-empty empty-text="This control has no permissions." empty-filtered-text="No permissions match your search. If more permissions are available you can load them by clicking 'Load More' below.">
            <template #cell(account)="data">
                <b-link :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
            </template>
            <template #cell(status)="data">
                <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
            </template>
            <template #cell(actions)="data">
                <b-button v-if="['ENABLED'].includes(data.item.status)" variant="outline-danger" size="sm" v-on:click="$emit('disable', 'permission', data.item.id)">Disable</b-button>
                <b-button v-else-if="['DISABLED'].includes(data.item.status)" variant="outline-success" size="sm" v-on:click="$emit('enable', 'permission', data.item.id)">Enable</b-button>
            </template>
        </b-table>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="$emit('load', 'permissions')">Refresh</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
        <b-button v-if="permissions" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? permissions.items.length) - page * 10, 0)">Next</b-button>
        <b-button v-if="permissions?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'permissions')" class="ml-2" :disabled="loading_more">Load More</b-button>
    </b-card-body>
    <b-card-footer v-if="permissions?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ permissions.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'account',
        label: 'Account',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'ControlPermissions',

    /**
     * EVENTS
     */
    emits: [ 'load', 'next', 'show', 'enable', 'disable' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading_more: String,
        permissions: Array,
        control: Object
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.permissions) {
                this.$emit('load', 'permissions', false);
            }
        }
    }
}
</script>