<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/accounts/${$route.params.id}`" exact exact-active-class="active">Account</b-nav-item>
            <b-nav-item v-if="root && account?.subtype === 'user'" :to="`/accounts/${$route.params.id}/tenants`" exact exact-active-class="active">Tenants</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/attributes`" exact exact-active-class="active">Attributes<b-badge class="ml-2" variant="danger">Data</b-badge></b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/factors`" exact exact-active-class="active">Factors</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/controls`" exact exact-active-class="active">Controls</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/tokens`" exact exact-active-class="active">Tokens</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/events`" exact exact-active-class="active">Events</b-nav-item>
        </b-nav>
        <div v-if="account?.subtype === 'client'" class="ml-auto">
            <b-button v-if="account.config.grant_types.includes('client_credentials')" variant="primary" v-on:click="$emit('show', 'create-account-token', { id: account.id, method: account.config.authentication.method, scopes: rules?.items.filter(rule => rule.subtype === 'scope') })" :disabled="!account.config.grant_types.includes('client_credentials') || !rules || (account.status !== 'ENABLED')">Test</b-button>
            <b-button v-else variant="outline-primary" v-on:click="$emit('show', 'test-account', { id: account.id, label: account.label, config: account.config, scopes: rules?.items.filter(rule => rule.subtype === 'scope') })" :disabled="!account.config.grant_types.includes('authorization_code') || !rules || (account.status !== 'ENABLED') || account.config.signed_request">Test</b-button>
        </div>
    </b-card-header>
    <b-card-body v-if="account" class="pb-0">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                    <b-form-input v-model="account.id" readonly></b-form-input>
                    <b-badge v-if="account.id === $store.state.account_id" style="position: absolute; right: 15px; bottom: 10px" variant="primary">YOU</b-badge>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" :state="validField('label')" invalid-feedback="Please provide a valid label." description="Please note this label is visible to the account.">
                    <b-form-input v-model="account.label" :state="validField('label')" :readonly="!canEdit()"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                    <b-form-select v-model="account.status" :options="canEdit() ? statuses.filter(status => ['ENABLED','DISABLED'].includes(status.value)) : statuses" :disabled="!canEdit()"></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body v-if="account?.config">
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
            </b-card-header>
            <b-collapse id="configuration" visible>
                <b-card-body>
                    <b-row>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Internal" label-align-sm="right" label-cols-sm="3" description="This indicates whether the account is considered internal.">
                                <b-form-checkbox v-model="account.config.internal" switch :disabled="!canEdit()"></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-body v-if="account.subtype === 'client'">
                    <b-card class="shadow rounded bg-white" no-body fluid>
                        <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'federation')">
                            <b-img src="/img/factors/oauth2/oidc.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                            <h5 class="text-secondary mb-0 mr-2">Federation<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge><b-badge class="ml-2 text-white" variant="openid">OpenID Connect</b-badge></h5>
                        </b-card-header>
                        <b-collapse id="federation" visible>
                            <b-card-body class="pb-0">
                                <b-row>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Scopes" label-align-sm="right" label-cols-sm="3" description="These are the allowed scopes. Note these are managed using rules under 'Controls' and that 'openid' must be included to support OpenID Connect.">
                                            <b-form-tags v-model="account.config.scopes" disabled></b-form-tags>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="Grant Types" label-align-sm="right" label-cols-sm="3" description="These are the allowed grant types." :state="validField('config.grant_types')" invalid-feedback="Please select a least 1 grant type. Only refresh token is not valid as this is an add-on to the other grants.">
                                            <b-form-checkbox-group v-model="account.config.grant_types" :options="grant_types" stacked switches :state="validField('config.grant_types')" :disabled="!canEdit()"></b-form-checkbox-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="account.config.grant_types.includes('authorization_code')">
                                        <b-form-group label="Login URI" label-align-sm="right" label-cols-sm="3" description="This is the login URI to redirect to." :state="validField('config.login_uri')" invalid-feedback="Please provide a valid login URI. Must be an URL.">
                                            <b-input-group>
                                                <b-form-input v-model="account.config.login_uri" :state="validField('config.login_uri')" :disabled="!canEdit()"></b-form-input>
                                                <b-input-group-append>
                                                    <b-button variant="outline-danger" v-on:click="account.config.login_uri = `https://${$store.state.tenant_id}.login${$store.state.domain}`" :disabled="!canEdit()">Reset</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="12" v-if="account.config.grant_types.includes('authorization_code')">
                                        <b-form-group label="Redirect URIs" label-align-sm="right" label-cols-sm="3" label-cols-xl="1" description="These are the allowed redirect URIs. Note that the order matters as the first URI is selected if none is explicitely requested." :state="validField('config.redirect_uris')" invalid-feedback="Please provide at least 1 valid redirect URI. Must be an URL.">
                                            <b-form-tags :value="account.config.redirect_uris" v-on:input="account.config.redirect_uris = $event" :state="validField('config.redirect_uris')" :tag-validator="isURL" :disabled="!canEdit()"></b-form-tags>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="account.config.grant_types.includes('authorization_code')">
                                        <b-form-group label="Response Types" label-align-sm="right" label-cols-sm="3" description="These are the allowed response types." :state="validField('config.response_types')" invalid-feedback="Please select at least 1 response type.">
                                            <b-form-checkbox-group v-model="account.config.response_types" :options="response_types" :state="validField('config.response_types')" stacked switches :disabled="!canEdit()"></b-form-checkbox-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="account.config.grant_types.includes('authorization_code') && account.config.response_types?.some(response_type => response_type.includes('code'))">
                                        <b-form-group label="Code Challenge Methods" label-align-sm="right" label-cols-sm="3" description="These are the allowed code challenge methods." :state="validField('config.code_challenge_methods')" invalid-feedback="Please select at least 1 code challenge method.">
                                            <b-form-checkbox-group v-model="account.config.code_challenge_methods" :options="code_challenge_methods" :state="validField('config.code_challenge_methods')" stacked switches :disabled="!canEdit()"></b-form-checkbox-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="account.config.grant_types.includes('authorization_code')">
                                        <b-form-group label-align-sm="right" label-cols-sm="3" description="This indicates whether authorization requests should be signed by the client.">
                                            <template #label>
                                                Signed Request<b-badge class="ml-2" variant="warning">NEW</b-badge>
                                            </template>
                                            <b-form-checkbox v-model="account.config.signed_request" switch :disabled="!canEdit()"></b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="account.config.grant_types.includes('authorization_code') && account.config.signed_request" :state="validField('config.jwks_uri')" invalid-feedback="Please provide a valid JWKS endpoint. Must be an URL.">
                                        <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the JSON Web Key Set (JWKS) endpoint of the client to validate a signed request.">
                                            <template #label>
                                                JWKS Endpoint<b-badge class="ml-2" variant="warning">NEW</b-badge>
                                            </template>
                                            <b-input-group>
                                                <b-form-input v-model="account.config.jwks_uri" :state="validField('config.jwks_uri')" :readonly="!canEdit()"></b-form-input>
                                                <b-input-group-append>
                                                    <b-button :href="account.config.jwks_uri" target="_blank" variant="outline-primary" :disabled="!account.config.jwks_uri">Open</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-card-body v-if="!account.config.grant_types.includes('authorization_code') || account.config.response_types?.some(response_type => response_type.includes('code'))">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'authentication')">
                                        <b-img src="/img/menu/factors.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2">Authentication</h5>
                                    </b-card-header>
                                    <b-collapse id="authentication" visible>
                                        <b-card-body>
                                            <b-row>
                                                <b-col xl="6" xxl="4">
                                                    <b-form-group label="Method" label-align-sm="right" label-cols-sm="3" description="This is the client authentication method." :state="validField('config.authentication.method')" invalid-feedback="Please select a method.">
                                                        <b-form-select v-model="account.config.authentication.method" :options="client_authentications" :state="validField('config.authentication.method')" v-on:change="account.config.authentication.factor = null" :disabled="!canEdit()"></b-form-select>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col xl="6" xxl="4" v-if="!['none','session'].includes(account.config.authentication.method)">
                                                    <b-form-group label="Factor" label-align-sm="right" label-cols-sm="3" description="This is the client authentication factor." :state="validField('config.authentication.factor')" invalid-feedback="Please select a factor.">
                                                        <b-input-group>
                                                            <b-form-select v-model="account.config.authentication.factor" :options="factors()" value-field="id" text-field="label" :state="validField('config.authentication.factor')" :disabled="!canEdit()"></b-form-select>
                                                            <b-input-group-append>
                                                                <b-button :to="`/enrollments/${account.config.authentication.factor}`" variant="outline-primary" :disabled="!account.config.authentication.factor">Open</b-button>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-card-body>
                            <b-card-body v-if="!(account.config.grant_types.includes('authorization_code') && account.config.response_types?.length === 1 && account.config.response_types[0] === 'none')">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'tokens')">
                                        <b-img src="/img/icons/token.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2">Tokens</h5>
                                    </b-card-header>
                                    <b-collapse id="tokens" visible>
                                        <b-card-body class="pb-0">
                                            <b-row>
                                                <b-col xl="6" xxl="4" class="mb-4" v-if="!(account.config.grant_types.includes('authorization_code') && !account.config.response_types?.some(response_type => response_type.includes('code')))">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/menu/controls.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Access<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge></h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long an access token is valid, i.e. how long the client can retain access." :state="validField('config.tokens.access.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 30 days.">
                                                                        <b-form-input v-model="account.config.tokens.access.exp" :state="validField('config.tokens.access.exp')" :readonly="!canEdit()"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                        <b-form-select v-model="account.config.tokens.access.use" :options="usages" disabled></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Audiences" label-align-sm="right" label-cols-sm="3" description="These are the audiences the access token can be used for." :state="validField('config.tokens.access.aud')" invalid-feedback="Please provide at least 1 valid audience.">
                                                                        <b-form-tags :value="account.config.tokens.access.aud" v-on:input="account.config.tokens.access.aud = $event" :state="validField('config.tokens.access.aud')" :disabled="!canEdit()"></b-form-tags>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Extension" label-align-sm="right" label-cols-sm="3" description="This is the extension run before each token generation and able to inject custom claims and scopes.">
                                                                        <b-input-group>
                                                                            <b-form-select v-model="account.config.tokens.access.ext" :options="extensions()" value-field="id" text-field="label" :disabled="!canEdit()"></b-form-select>
                                                                            <b-input-group-append>
                                                                                <b-button :to="`/extensions/${account.config.tokens.access.ext}`" variant="outline-primary" :disabled="!account.config.tokens.access.ext">Open</b-button>
                                                                            </b-input-group-append>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the JSON Web Key Set (JWKS) endpoint of the audience (API) to encrypt access tokens with. Note that if you encrypt the token with your own keys it can't be used against the Quasr APIs." :state="validField('config.tokens.access.jwk')" invalid-feedback="Please provide a valid JWKS endpoint. Must be an URL.">
                                                                        <template #label>
                                                                            Encryption (JWKS)<b-badge class="ml-2" variant="warning">NEW</b-badge>
                                                                        </template>
                                                                        <b-form-input v-model="account.config.tokens.access.jwk" :state="validField('config.tokens.access.jwk')" :readonly="!canEdit()"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                                <b-col xl="6" xxl="4" class="mb-4" v-if="account.config.scopes?.includes('openid')">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/account.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Identity<b-badge class="ml-2 text-white" variant="openid">OpenID Connect</b-badge></h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long an identity token is valid, i.e. how fast the client must consume the token." :state="validField('config.tokens.identity.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 1 day.">
                                                                        <b-form-input v-model="account.config.tokens.identity.exp" :state="validField('config.tokens.identity.exp')" :readonly="!canEdit()"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                        <b-form-select v-model="account.config.tokens.identity.use" :options="usages" disabled></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Extension" label-align-sm="right" label-cols-sm="3" description="This is the extension run before each token generation and able to inject custom claims.">
                                                                        <b-input-group>
                                                                            <b-form-select v-model="account.config.tokens.identity.ext" :options="extensions()" value-field="id" text-field="label" :disabled="!canEdit()"></b-form-select>
                                                                            <b-input-group-append>
                                                                                <b-button :to="`/extensions/${account.config.tokens.identity.ext}`" variant="outline-primary" :disabled="!account.config.tokens.identity.ext">Open</b-button>
                                                                            </b-input-group-append>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the JSON Web Key Set (JWKS) endpoint of the client to encrypt identity tokens with." :state="validField('config.tokens.identity.jwk')" invalid-feedback="Please provide a valid JWKS endpoint. Must be an URL.">
                                                                        <template #label>
                                                                            Encryption (JWKS)<b-badge class="ml-2" variant="warning">NEW</b-badge>
                                                                        </template>
                                                                        <b-form-input v-model="account.config.tokens.identity.jwk" :state="validField('config.tokens.identity.jwk')" :readonly="!canEdit()"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                                <b-col xl="6" xxl="4" class="mb-4" v-if="account.config.grant_types.includes('authorization_code') && account.config.response_types?.some(response_type => response_type.includes('code'))">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/code.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Code<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge></h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long an authorization code is valid, i.e. how fast the client must exchange it for tokens.">
                                                                        <b-form-input v-model="account.config.tokens.code.exp" readonly></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                        <b-form-select v-model="account.config.tokens.code.use" :options="usages" disabled></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                                <b-col xl="6" xxl="4" class="mb-4" v-if="account.config.grant_types.includes('refresh_token')">
                                                    <b-card class="shadow rounded bg-white" no-body fluid>
                                                        <b-card-header class="bg-light d-flex">
                                                            <b-img src="/img/icons/refresh.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                                            <h5 class="text-secondary mb-0 mr-2">Refresh<b-badge class="ml-2" variant="oauth2">OAuth 2.0</b-badge></h5>
                                                        </b-card-header>
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Expiration" label-align-sm="right" label-cols-sm="3" description="This is how long a refresh token is valid, i.e. how long the client can use it to obtain new tokens." :state="validField('config.tokens.refresh.exp')" invalid-feedback="Please provide a valid expiration. You can express it in seconds, minutes, hours, days, weeks or years. The maximum is 1 year.">
                                                                        <b-form-input v-model="account.config.tokens.refresh.exp" :state="validField('config.tokens.refresh.exp')" :readonly="!canEdit()"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-form-group label="Usage" label-align-sm="right" label-cols-sm="3" description="This is how many times the token can be used.">
                                                                        <b-form-select v-model="account.config.tokens.refresh.use" :options="usages" disabled></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-card-body>
            </b-collapse>
        </b-card>
    </b-card-body>
    <b-card-body v-if="account">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="account.created_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="account.created_by" readonly></b-form-input>
                        <b-badge v-if="account.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${account.created_by}`" variant="outline-primary" :disabled="account.created_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="account.updated_at">
                <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="account.updated_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="account.updated_by">
                <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="account.updated_by" readonly></b-form-input>
                        <b-badge v-if="account.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${account.updated_by}`" variant="outline-primary" :disabled="account.updated_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="account.expires_at">
                <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="account.expires_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="success" v-on:click="saveAccount()" :disabled="!canEdit() || !validAccount()">Save</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="$emit('load', 'account')">Refresh</b-button>
        <b-button variant="outline-danger" class="ml-auto" v-on:click="$emit('show', 'delete-account', account)" :disabled="!canEdit()">Delete</b-button>
    </b-card-body>
    <b-card-footer v-if="account?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ account.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const SUBTYPES = [
    { value: 'user', text: 'User' },
    { value: 'client', text: 'Client' }
];
const STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const GRANT_TYPES = [
    { value: 'authorization_code', text: 'Authorization Code (Browser)' },
    { value: 'urn:ietf:params:oauth:grant-type:jwt-bearer', text: 'JWT Bearer (Native)' },
    { value: 'client_credentials', text: 'Client Credentials' },
    { value: 'refresh_token', text: 'Refresh Token' }
];
const RESPONSE_TYPES = [
    { value: 'code', text: 'Authorization Code' },
    { value: 'code id_token', text: 'Authorization Code & ID Token' },
    { value: 'id_token', text: 'ID Token' },
    { value: 'none', text: 'None' }
];
const CODE_CHALLENGE_METHODS = [
    { value: 'plain', text: 'Cleartext' },
    { value: 'S256', text: 'Hash' }
];
const CLIENT_AUTHENTICATIONS = [
    { value: 'client_secret_basic', text: 'Client Secret (Header)' },
    { value: 'client_secret_post', text: 'Client Secret (Body)' },
    { value: 'private_key_jwt', text: 'Private Key (JWT)' },
    { value: 'session', text: 'Session' },
    { value: 'none', text: 'None' }
];
const USAGES = [
    { value: 0, text: 'Unlimited' },
    { value: 1, text: 'One-Time' }
];
const TIME_REGEX = new RegExp(/^(\d+|\d+\.\d+) ?(seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|weeks?|w|years?|yrs?|y)$/i);
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const YEAR = DAY * 365.25;

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'Account',

    /**
     * EVENTS
     */
     emits: [ 'alert', 'login', 'loading', 'load', 'next', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        account: Object,
        enrollments: Array,
        rules: Array,
        root: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // SUBTYPES
            subtypes: SUBTYPES,
            // STATUSES
            statuses: STATUSES,
            // GRANT TYPES
            grant_types: GRANT_TYPES,
            // RESPONSE TYPES
            response_types: RESPONSE_TYPES,
            // CODE CHALLENGE METHODS
            code_challenge_methods: CODE_CHALLENGE_METHODS,
            // CLIENT AUTHENTICATIONS
            client_authentications: CLIENT_AUTHENTICATIONS,
            // USAGES
            usages: USAGES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.enrollments) {
                    this.$emit('load', 'enrollments', true); // LOAD ALL
                } else if (this.enrollments.nextToken) {
                    this.$emit('next', 'enrollments', true); // LOAD ALL
                }
                if (!this.$store.state.factors) {
                    this.$emit('load', 'factors', true); // LOAD ALL
                } else if (this.$store.state.factors.nextToken) {
                    this.$emit('next', 'factors', this.$store.state.factors.nextToken, true); // LOAD ALL
                }
                if (!this.$store.state.extensions) {
                    this.$emit('load', 'extensions', true); // LOAD ALL
                } else if (this.$store.state.extensions.nextToken) {
                    this.$emit('next', 'extensions', this.$store.state.extensions.nextToken, true); // LOAD ALL
                }
                if (!this.rules) {
                    this.$emit('load', 'rules', true); // LOAD ALL
                } else if (this.rules.nextToken) {
                    this.$emit('next', 'rules', true); // LOAD ALL
                }
            }
        },

        /**
         * ACCOUNT
         */
        async saveAccount() {
            this.$emit('loading', 'Saving');
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateAccount($input: UpdateAccountInput!) {
                                updateAccount(input: $input) {
                                    id
                                    label
                                    subtype
                                    status
                                    config {
                                        internal
                                        scopes
                                        grant_types
                                        response_types
                                        redirect_uris
                                        login_uri
                                        code_challenge_methods
                                        signed_request
                                        jwks_uri
                                        authentication {
                                            method
                                            factor
                                            output
                                        }
                                        tokens {
                                            access {
                                                exp
                                                use
                                                aud
                                                ext
                                                jwk
                                            }
                                            code {
                                                exp
                                                use
                                            }
                                            identity {
                                                exp
                                                use
                                                ext
                                                jwk
                                            }
                                            refresh {
                                                exp
                                                use    
                                            }
                                        }
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.account.id}",
                                "label": "${this.account.label}",
                                "status": "${this.account.status}",
                                "subtype": "${this.account.subtype}",
                                "config": {
                                    "internal": ${this.account.config.internal}${this.account.subtype === 'client' ? `,
                                    "grant_types": ${JSON.stringify(this.account.config.grant_types) /* ARRAY */},
                                    "login_uri": "${this.account.config.login_uri}",
                                    "redirect_uris": ${JSON.stringify(this.account.config.redirect_uris) /* ARRAY */},
                                    "response_types": ${JSON.stringify(this.account.config.response_types) /* ARRAY */},
                                    "code_challenge_methods": ${JSON.stringify(this.account.config.code_challenge_methods) /* ARRAY */},
                                    "signed_request": ${this.account.config.signed_request},
                                    "jwks_uri": ${JSON.stringify(this.account.config.jwks_uri || null) /* NULLABLE */},
                                    "authentication": {
                                        "method": "${this.account.config.authentication.method}",
                                        "factor": ${JSON.stringify(this.account.config.authentication.factor || null) /* NULLABLE */}
                                    },
                                    "tokens": {
                                        "access": {
                                            "exp": "${this.account.config.tokens.access.exp}",
                                            "aud": ${JSON.stringify(this.account.config.tokens.access.aud) /* ARRAY */},
                                            "ext": ${JSON.stringify(this.account.config.tokens.access.ext || null) /* NULLABLE */},
                                            "jwk": ${JSON.stringify(this.account.config.tokens.access.jwk || null) /* NULLABLE */}
                                        },
                                        "identity": {
                                            "exp": "${this.account.config.tokens.identity.exp}",
                                            "ext": ${JSON.stringify(this.account.config.tokens.identity.ext || null) /* NULLABLE */},
                                            "jwk": ${JSON.stringify(this.account.config.tokens.identity.jwk || null) /* NULLABLE */}
                                        },
                                        "refresh": {
                                            "exp": "${this.account.config.tokens.refresh.exp}"
                                        }
                                    }` : ''}
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const account = (await response.json()).data.updateAccount;
                    this.$emit('load', 'account');
                    this.$emit('alert', 'The account has been updated', 'Account', 'success', 5000);
                    if (account.config?.authentication?.output) {
                        this.$emit('show', 'save-output', {
                            label: account.config.authentication.method === 'private_key_jwt' ? 'Private Key' : 'Secret',
                            subtype: account.config.authentication.method === 'private_key_jwt' ? 'jwt:spki' : 'secret:password',
                            output: account.config.authentication.output
                        });
                    }
                    if (account.subtype === 'client') {
                        this.$emit('load', 'enrollments', true); // LOAD ALL
                    }
                    this.$emit('load', `${account.subtype}s`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save account.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save account.', 'Account', 'danger');
            }
            this.$emit('loading', undefined);
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED','DISABLED'].includes(this.account?.status);
        },

        validAccount() {
            if (!this.validField('label')) return false;
            if (this.account.subtype === 'client') {
                if (!this.validField('config.grant_types')) return false;
                if (this.account.config.grant_types.some(grant_type => [ 'authorization_code' ].includes(grant_type))) {
                    if (!this.validField('config.login_uri')) return false;
                    if (!this.validField('config.redirect_uris')) return false;
                    if (!this.validField('config.response_types')) return false;
                    if (!this.validField('config.code_challenge_methods')) return false;
                    if (this.account.config.signed_request) {
                        if (!this.validField('config.jwks_uri')) return false;
                    }
                }
                if (this.account.config.grant_types.some(grant_type => [ 'client_credentials', 'urn:ietf:params:oauth:grant-type:jwt-bearer' ].includes(grant_type))) {
                    if (!this.validField('config.authentication.method')) return false;
                }
                if (!['none', 'session'].includes(this.account.config.authentication.method)) {
                    if (!this.validField('config.authentication.factor')) return false;
                }
                if (!(this.account.config.grant_types.includes('authorization_code') && this.account.config.response_types?.length === 1 && this.account.config.response_types[0] === 'none')) {
                    if (!(this.account.config.grant_types.includes('authorization_code') && !this.account.config.response_types?.some(response_type => response_type.includes('code')))) {
                        if (!this.validField('config.tokens.access.exp')) return false;
                        if (!this.validField('config.tokens.access.aud')) return false;
                        if (this.account.config.tokens.access.jwk) {
                            if (!this.validField('config.tokens.access.jwk')) return false;
                        }
                    }
                    if (this.account.config.scopes?.includes('openid')) {
                        if (!this.validField('config.tokens.identity.exp')) return false;
                        if (this.account.config.tokens.identity.jwk) {
                            if (!this.validField('config.tokens.identity.jwk')) return false;
                        }
                    }
                    if (this.account.config.grant_types.includes('refresh_token')) {
                        if (!this.validField('config.tokens.refresh.exp')) return false;
                    }
                }
            }
            return true;
        },

        validField(field) {
            switch (field) {
                case 'label':
                    return !!this.account.label;
                case 'config.grant_types':
                    return this.account.config.grant_types && this.account.config.grant_types.length > 0 && !(this.account.config.grant_types.length === 1 && this.account.config.grant_types[0] === 'refresh_token');
                case 'config.login_uri':
                    return !!this.account.config.login_uri && this.isURL(this.account.config.login_uri);
                case 'config.redirect_uris':
                    return !!this.account.config.redirect_uris && this.account.config.redirect_uris.length > 0;
                case 'config.response_types':
                    return !!this.account.config.response_types && this.account.config.response_types.length > 0;
                case 'config.code_challenge_methods':
                    return !!this.account.config.code_challenge_methods && this.account.config.code_challenge_methods.length > 0;
                case 'config.jwks_uri':
                    return !!this.account.config.jwks_uri && this.isURL(this.account.config.jwks_uri);
                case 'config.authentication.method':
                    return this.account.config.grant_types.some(grant_type => [ 'client_credentials', 'urn:ietf:params:oauth:grant-type:jwt-bearer' ].includes(grant_type)) ? (!!this.account.config.authentication.method && this.account.config.authentication.method !== 'none') : undefined;
                case 'config.authentication.factor':
                    return !!this.account.config.authentication.factor;
                case 'config.tokens.access.exp':
                    if (!TIME_REGEX.test(this.account.config.tokens.access.exp)) return false;
                    return this.convert(this.account.config.tokens.access.exp) <= this.convert('30d');
                case 'config.tokens.access.aud':
                    return !!this.account.config.tokens.access.aud && this.account.config.tokens.access.aud.length > 0;
                case 'config.tokens.access.jwk':
                    return this.account.config.tokens.access.jwk ? this.isURL(this.account.config.tokens.access.jwk) : undefined;
                case 'config.tokens.identity.exp':
                    if (!TIME_REGEX.test(this.account.config.tokens.identity.exp)) return false;
                    return this.convert(this.account.config.tokens.identity.exp) <= this.convert('1d');
                case 'config.tokens.identity.jwk':
                    return !!this.account.config.tokens.identity.jwk ? this.isURL(this.account.config.tokens.identity.jwk) : undefined;
                case 'config.tokens.refresh.exp':
                    if (!TIME_REGEX.test(this.account.config.tokens.refresh.exp)) return false;
                    return this.convert(this.account.config.tokens.refresh.exp) <= this.convert('1y');
                default:
                    return false;
            }
        },

        convert(time) {
            const matched = TIME_REGEX.exec(time);
            const value = parseFloat(matched[1]);
            const unit = matched[2].toLowerCase();
            switch (unit) {
                case 'sec':
                case 'secs':
                case 'second':
                case 'seconds':
                case 's':
                    return Math.round(value);
                case 'minute':
                case 'minutes':
                case 'min':
                case 'mins':
                case 'm':
                    return Math.round(value * MINUTE);
                case 'hour':
                case 'hours':
                case 'hr':
                case 'hrs':
                case 'h':
                    return Math.round(value * HOUR);
                case 'day':
                case 'days':
                case 'd':
                    return Math.round(value * DAY);
                case 'week':
                case 'weeks':
                case 'w':
                    return Math.round(value * WEEK);
                default:
                    return Math.round(value * YEAR);
            }
        },

        /**
         * URL
         */
        isURL(url) {
            try {
                new URL(url);
                return true;
            } catch (error) {
                return false;
            }
        },

        /**
         * UTILITIES
         */
        factors() {

            // Initialize
            var enrollments = this.enrollments?.items || [];
            var factors = this.$store.state.factors?.items || [];

            // Filter based on method
            switch (this.account.config.authentication.method) {
                // PRIVATE KEY JWT
                case 'private_key_jwt':
                    enrollments = enrollments.filter(enrollment => ['jwt:spki','jwt:jwks'].includes(enrollment.subtype));
                    factors = factors.filter(factor => ['jwt:spki','jwt:jwks'].includes(factor.subtype));
                    break;
                // CLIENT SECRET
                default:
                    enrollments = enrollments.filter(enrollment => ['secret:password'].includes(enrollment.subtype));
                    factors = factors.filter(factor => ['secret:password'].includes(factor.subtype));
                    break;
            }

            // Clone and disable based on status
            enrollments = enrollments.map(enrollment => ({
                id: enrollment.id,
                label: enrollment.label,
                disabled: (enrollment.status !== 'ENABLED')
            }));
            factors = factors.map(factor => ({
                id: factor.id,
                label: factor.label,
                disabled: (factor.status !== 'ENABLED')
            }));

            // Return select list
            return [{ label: 'Use existing factor:', disabled: true }].concat(enrollments).concat(
                    [{ label: 'Create new factor:', disabled: true }]).concat(factors);
            
        },

        extensions() {

            // Clone and disable based on status
            return (this.$store.state.extensions?.items.map(extension => ({
                id: extension.id,
                label: extension.label,
                disabled: (extension.status !== 'ENABLED')
            })) || []).concat({ id: null, label: 'None' });

        }
    }
}
</script>