<template>
    <b-overlay :show="loading || loading2">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading || loading2 }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img :src="account?.subtype === 'client' ? '/img/menu/clients.svg' : '/img/icons/account.svg'" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">{{ account?.subtype === 'client' ? 'Application' : 'User' }}</h3>
            </b-card-header>
            <RouterView v-slot="{ Component }">
                <component :is="Component" :filter="filter" :variant="variant" :root="root" :account="account" :tenants="tenants" :claims="claims" :enrollments="enrollments" :consents="consents" :permissions="permissions" :rules="rules" :tokens="tokens" :events="events" @alert="sendAlert" @login="sendLogin" @loading="setLoading" @load="sendLoad" @next="sendNext" @show="sendShow" :loading_more="loading_more" @disable="disableData" @enable="enableData"/>
            </RouterView>
        </b-card>

        <!-- SET FILTER -->
        <b-modal id="set-filter" title="Set Filter" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Date (Min)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.time_min_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_min_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.time_min_date">
                <b-col>
                    <b-form-group label="Time (Min)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.time_min_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_min_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Date (Max)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.time_max_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_max_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.time_max_date">
                <b-col>
                    <b-form-group label="Time (Max)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.time_max_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_max_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Type" label-align-sm="right" label-cols-sm="3">
                        <b-input-group>
                            <b-form-select v-model="filter_input.type" :options="filter_types"></b-form-select>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.type" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Origin" label-align-sm="right" label-cols-sm="3" description="Please note field is case sensitive.">
                        <b-input-group>
                            <b-form-input v-model="filter_input.origin"></b-form-input>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.origin" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Action" label-align-sm="right" label-cols-sm="3" description="Please note field is case sensitive.">
                        <b-input-group>
                            <b-form-input v-model="filter_input.action"></b-form-input>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.action" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Result" label-align-sm="right" label-cols-sm="3">
                        <b-input-group>
                            <b-form-select v-model="filter_input.result" :options="filter_results"></b-form-select>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.result" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-row class="w-100">
                    <b-col class="d-flex px-0">
                        <b-button variant="outline-secondary" v-on:click="$bvModal.hide('set-filter')">Cancel</b-button>
                        <b-button variant="success" class="ml-auto" v-on:click="updateFilter()">Update</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FILTER_TYPES = [
    { value: 'API', text: 'API' },
    { value: 'DATA', text: 'Data' },
    { value: 'DATABASE', text: 'Database' },
    { value: 'COMMUNICATION', text: 'Communication' },
    { value: 'AUTHENTICATION', text: 'Authentication' },
    { value: 'AUTHORIZATION', text: 'Authorization' },
];
const FILTER_RESULTS = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'SUCCESS', text: 'Success' },
    { value: 'FAILED', text: 'Failed' },
];

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'AccountCard',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading: Boolean,
        root: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // TENANTS
            tenants: undefined,
            // CLAIMS
            claims: undefined,
            // ENROLLMENTS
            enrollments: undefined,
            // CONSENTS
            consents: undefined,
            // PERMISSIONS
            permissions: undefined,
            // RULES
            rules: undefined,
            // TOKENS
            tokens: undefined,
            // EVENTS
            events: undefined,
            // LOADING
            loading2: undefined,
            // ACCOUNT
            account: undefined,
            // LOADING (MORE)
            loading_more: false,
            // FILTER (INPUT)
            filter_input: {},
            // FILTER (LIST)
            filter_list: {
                account_id: {
                    eq: this.$route.params.id
                }
            },
            // TYPES (FILTER)
            filter_types: FILTER_TYPES,
            // RESULTS (FILTER)
            filter_results: FILTER_RESULTS
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.account) {
                this.getAccount();
            }
        },

        /**
         * LOADING
         */
        setLoading(loading) {
            this.loading2 = loading;
        },

        /**
         * EVENTS
         */
        sendAlert(...args) {
            this.$emit('alert', ...args);
        },

        sendLogin(...args) {
            this.$emit('login', ...args);
        },

        sendLoad(...args) {
            if (args[0] === 'account') {
                this.getAccount();
            } else if (args[0] === 'tenants') {
                this.listTenants();
            } else if (args[0] === 'claims') {
                this.listClaims();
            } else if (args[0] === 'enrollments') {
                this.loadEnrollments(args[1]);
            } else if (args[0] === 'consents') {
                this.listConsents();
            } else if (args[0] === 'permissions') {
                this.listPermissions();
            } else if (args[0] === 'rules') {
                this.listRules(args[1]);
            } else if (args[0] === 'tokens') {
                this.listTokens();
            } else if (args[0] === 'events') {
                this.updateFilter();
            } else {
                this.$emit('load', ...args);
            }
        },

        sendNext(...args) {
            if (args[0] === 'tenants') {
                this.nextTenants();
            } else if (args[0] === 'claims') {
                this.nextClaims();
            } else if (args[0] === 'enrollments') {
                this.nextEnrollments(args[1]);
            } else if (args[0] === 'consents') {
                this.nextConsents();
            } else if (args[0] === 'permissions') {
                this.nextPermissions();
            } else if (args[0] === 'rules') {
                this.nextRules(args[1]);
            } else if (args[0] === 'tokens') {
                this.nextTokens();
            } else if (args[0] === 'events') {
                this.nextEvents();
            } else {
                this.$emit('next', ...args);
            }
        },

        sendShow(...args) {
            if (args[0] === 'set-filter') {
                this.$bvModal.show('set-filter');
            } else {
                this.$emit('show', ...args);
            }
        },

        /**
         * FILTER
         */
        updateFilter() {
            this.$bvModal.hide('set-filter');
            this.filter_list = {
                time: this.getTimeFilter(),
                type: this.filter_input.type ? { eq: this.filter_input.type } : undefined,
                origin: this.filter_input.origin ? { contains: this.filter_input.origin } : undefined,
                action: this.filter_input.action ? { contains: this.filter_input.action } : undefined,
                result: this.filter_input.result ? { eq: this.filter_input.result } : undefined,
                account_id: { eq: this.$route.params.id } // FIXED
            };
            this.listEvents();
        },

        getTimeFilter() {
            if (this.filter_input.time_min_date) {
                if (this.filter_input.time_max_date) {
                    return { between: [ `${this.filter_input.time_min_date}T${this.filter_input.time_min_time || '00:00:00'}Z`, `${this.filter_input.time_max_date}T${this.filter_input.time_max_time || '24:00:00'}Z`] }
                } else {
                    return { ge: `${this.filter_input.time_min_date}T${this.filter_input.time_min_time || '00:00:00'}Z` };
                }
            } else if (this.filter_input.time_max_date) {
                return { le: `${this.filter_input.time_max_date}T${this.filter_input.time_max_time || '24:00:00'}Z` };
            } else {
                return undefined;
            }
        },

        /**
         * ACTIONS
         */
        async disableData(resource, id) {
            this.loading2 = 'Disabling';
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation update${resource.charAt(0).toUpperCase() + resource.slice(1)}($input: Update${resource.charAt(0).toUpperCase() + resource.slice(1)}Input!) {
                                update${resource.charAt(0).toUpperCase() + resource.slice(1)}(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${id}",
                                "status": "DISABLED"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    (await response.json()).data[`update${resource.charAt(0).toUpperCase() + resource.slice(1)}`];
                    switch (resource) {
                        case 'enrollment':
                            this.$emit('alert', 'The factor has been disabled.', 'Factor', 'success', 5000);
                            break;
                        case 'claim':
                            this.$emit('alert', 'The attribute has been disabled.', 'Attribute', 'success', 5000);
                            break;
                        default:
                            this.$emit('alert', `The ${resource} has been disabled.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'success', 5000);
                    }
                    this.sendLoad(`${resource}s`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', `Failed to disable ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
                }

            } catch (error) {
                this.$emit('alert', `Failed to disable ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
            }
            this.loading2 = undefined;
        },

        async enableData(resource, id) {
            this.loading2 = 'Enabling';
            try {

                // SEND REQUEST
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation update${resource.charAt(0).toUpperCase() + resource.slice(1)}($input: Update${resource.charAt(0).toUpperCase() + resource.slice(1)}Input!) {
                                update${resource.charAt(0).toUpperCase() + resource.slice(1)}(input: $input) {
                                    id
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${id}",
                                "status": "ENABLED"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    (await response.json()).data[`update${resource.charAt(0).toUpperCase() + resource.slice(1)}`];
                    switch (resource) {
                        case 'enrollment':
                            this.$emit('alert', 'The factor has been enabled.', 'Factor', 'success', 5000);
                            break;
                        case 'claim':
                            this.$emit('alert', 'The attribute has been enabled.', 'Attribute', 'success', 5000);
                            break;
                        default:
                            this.$emit('alert', `The ${resource} has been enabled.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'success', 5000);
                    }
                    this.sendLoad(`${resource}s`);
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', `Failed to enable ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
                }

            } catch (error) {
                this.$emit('alert', `Failed to enable ${resource}.`, resource.charAt(0).toUpperCase() + resource.slice(1), 'danger');
            }
            this.loading2 = undefined;
        },

        /**
         * ACCOUNT
         */
        async getAccount() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getAccount($id: ID!) {
                                getAccount(id: $id) {
                                    id
                                    label
                                    subtype
                                    status
                                    config {
                                        internal
                                        scopes
                                        grant_types
                                        response_types
                                        redirect_uris
                                        login_uri
                                        code_challenge_methods
                                        signed_request
                                        jwks_uri
                                        authentication {
                                            method
                                            factor
                                        }
                                        tokens {
                                            access {
                                                exp
                                                use
                                                aud
                                                ext
                                                jwk
                                            }
                                            code {
                                                exp
                                                use
                                            }
                                            identity {
                                                exp
                                                use
                                                ext
                                                jwk
                                            }
                                            refresh {
                                                exp
                                                use    
                                            }
                                        }
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.account = (await response.json()).data.getAccount;
                    // SET DATES
                    this.account.created_at = this.account.created_at ? new Date(this.account.created_at) : undefined;
                    this.account.updated_at = this.account.updated_at ? new Date(this.account.updated_at) : undefined;
                    this.account.expires_at = this.account.expires_at ? new Date(this.account.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.account.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain account.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain account.', 'Account', 'danger');
            }
            this.loading2 = undefined;
        },

        /**
         * TENANTS
         */
        async listTenants() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listTenants($filter: TableTenantFilterInput, $limit: Int) {
                                listTenants(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        subscription
                                        created_at
                                        metrics {
                                            maa
                                            mac
                                        }
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.tenants = (await response.json()).data.listTenants;
                    // ADD REFRESH DATE
                    this.tenants.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.tenants.nextToken) {
                        this.$emit('alert', 'More tenants are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tenants.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tenants.', 'Account', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextTenants() {
            if (!this.tenants.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listTenants($filter: TableTenantFilterInput, $limit: Int, $nextToken: String) {
                                listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        subscription
                                        created_at
                                        metrics {
                                            maa
                                            mac
                                        }
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.tenants.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tenants = (await response.json()).data.listTenants;
                    // ADD NEW TENANTS
                    for (const tenant of tenants.items) {
                        this.tenants.items.push(tenant);
                    }
                    // SET NEXT TOKEN
                    this.tenants.nextToken = tenants.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (tenants.nextToken) {
                        this.$emit('alert', 'More tenants are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tenants.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tenants.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * CLAIMS
         */
        async listClaims() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listClaims($account: ID!, $limit: Int) {
                                listClaims(account: $account, limit: $limit) {
                                    items {
                                        id
                                        label
                                        value
                                        status
                                        subtype
                                        attribute
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "account": "${this.$route.params.id}",
                            "limit": 50
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.claims = (await response.json()).data.listClaims;
                    // ADD REFRESH DATE
                    this.claims.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.claims.nextToken) {
                        this.$emit('alert', 'More attributes are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain attributes.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain attributes.', 'Account', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextClaims() {
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listClaims($account: ID!, $limit: Int, $nextToken: String) {
                                listClaims(account: $account, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        value
                                        status
                                        subtype
                                        attribute
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "account": "${this.$route.params.id}",
                            "limit": 50,
                            "nextToken": "${this.claims.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const claims = (await response.json()).data.listClaims;
                    // ADD NEW CONSENTS
                    for (const claim of claims.items) {
                        this.claims.items.push(claim);
                    }
                    // SET NEXT TOKEN
                    this.claims.nextToken = claims.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (claims.nextToken) {
                        this.$emit('alert', 'More attributes are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain attributes.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain attributes.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * ENROLLMENTS
         */
        async loadEnrollments(all) {
            this.loading2 = 'Loading';
            try {

                // LIST ENROLLMENTS
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEnrollments($filter: TableEnrollmentFilterInput, $limit: Int) {
                                listEnrollments(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        factor
                                        score
                                        status
                                        subtype
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.enrollments = (await response.json()).data.listEnrollments;
                    // ADD REFRESH DATE
                    this.enrollments.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.enrollments.nextToken) {
                        if (all) {
                            return this.nextEnrollments(all); // KEEP LOADING
                        } else {
                            this.$emit('alert', 'More factors are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain factors.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain factors.', 'Account', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextEnrollments(all) {
            this.loading_more = true;
            try {

                // LIST ENROLLMENTS
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEnrollments($filter: TableEnrollmentFilterInput, $limit: Int, $nextToken: String) {
                                listEnrollments(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        factor
                                        score
                                        status
                                        subtype
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.enrollments.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const enrollments = (await response.json()).data.listEnrollments;
                    // ADD NEW ENROLLMENTS
                    for (const enrollment of enrollments.items) {
                        this.enrollments.items.push(enrollment);
                    }
                    // SET NEXT TOKEN
                    this.enrollments.nextToken = enrollments.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (enrollments.nextToken) {
                        if (all) {
                            return this.nextEnrollments(all); // KEEP LOADING
                        } else {
                            this.$emit('alert', 'More factors are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain factors.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain factors.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * CONSENTS
         */
        async listConsents() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listConsents($filter: TableConsentFilterInput, $limit: Int) {
                                listConsents(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.consents = (await response.json()).data.listConsents;
                    // ADD TYPE
                    for (const consent of this.consents.items) {
                        consent.type = 'consent';
                    }
                    // ADD REFRESH DATE
                    this.consents.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.consents.nextToken) {
                        this.$emit('alert', 'More consents are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain consents.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain consents.', 'Account', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextConsents() {
            if (!this.consents.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listConsents($filter: TableConsentFilterInput, $limit: Int, $nextToken: String) {
                                listConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.consents.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const consents = (await response.json()).data.listConsents;
                    // ADD NEW CONSENTS
                    for (const consent of consents.items) {
                        consent.type = 'consent';
                        this.consents.items.push(consent);
                    }
                    // SET NEXT TOKEN
                    this.consents.nextToken = consents.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (consents.nextToken) {
                        this.$emit('alert', 'More consents are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain consents.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain consents.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * PERMISSIONS
         */
        async listPermissions() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listPermissions($filter: TablePermissionFilterInput, $limit: Int) {
                                listPermissions(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.permissions = (await response.json()).data.listPermissions;
                    // ADD TYPE
                    for (const permission of this.permissions.items) {
                        permission.type = 'permission';
                    }
                    // ADD REFRESH DATE
                    this.permissions.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.permissions.nextToken) {
                        this.$emit('alert', 'More permissions are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain permissions.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain permissions.', 'Account', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextPermissions() {
            if (!this.permissions.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listPermissions($filter: TablePermissionFilterInput, $limit: Int, $nextToken: String) {
                                listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        control
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.permissions.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const permissions = (await response.json()).data.listPermissions;
                    // ADD NEW PERMISSIONS
                    for (const permission of permissions.items) {
                        permission.type = 'permission';
                        this.permissions.items.push(permission);
                    }
                    // SET NEXT TOKEN
                    this.permissions.nextToken = permissions.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (permissions.nextToken) {
                        this.$emit('alert', 'More permissions are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain permissions.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain permissions.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * RULES
         */
        async listRules(all) {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listRules($filter: TableRuleFilterInput, $limit: Int) {
                                listRules(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        value
                                        status
                                        control
                                        subtype
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.rules = (await response.json()).data.listRules;
                    // ADD TYPE
                    for (const rule of this.rules.items) {
                        rule.type = 'rule';
                    }
                    // ADD REFRESH DATE
                    this.rules.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.rules.nextToken) {
                        if (all) {
                            return this.nextRules(all); // KEEP LOADING
                        } else {
                            this.$emit('alert', 'More rules are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain rules.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain rules.', 'Account', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextRules(all) {
            if (!this.rules.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listRules($filter: TableRuleFilterInput, $limit: Int, $nextToken: String) {
                                listRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        value
                                        status
                                        control
                                        subtype
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.rules.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const rules = (await response.json()).data.listRules;
                    // ADD NEW RULES
                    for (const rule of rules.items) {
                        rule.type = 'rule';
                        this.rules.items.push(rule);
                    }
                    // SET NEXT TOKEN
                    this.rules.nextToken = rules.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (rules.nextToken) {
                        if (all) {
                            return this.nextRules(all); // KEEP LOADING
                        } else {
                            this.$emit('alert', 'More rules are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                        }
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain rules.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain rules.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * TOKENS
         */
        async listTokens() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listTokens($filter: TableTokenFilterInput, $limit: Int) {
                                listTokens(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        status
                                        subtype
                                        created_at
                                        expires_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.tokens = (await response.json()).data.listTokens;
                    // ADD REFRESH DATE
                    this.tokens.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.tokens.nextToken) {
                        this.$emit('alert', 'More tokens are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tokens.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tokens.', 'Account', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextTokens() {
            if (!this.tokens.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listTokens($filter: TableTokenFilterInput, $limit: Int, $nextToken: String) {
                                listTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        status
                                        subtype
                                        created_at
                                        expires_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "account": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.tokens.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tokens = (await response.json()).data.listTokens;
                    // ADD NEW TOKENS
                    for (const token of tokens.items) {
                        this.tokens.items.push(token);
                    }
                    // SET NEXT TOKEN
                    this.tokens.nextToken = tokens.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (tokens.nextToken) {
                        this.$emit('alert', 'More tokens are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tokens.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tokens.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * EVENTS
         */
        async listEvents() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEvents($filter: TableEventFilterInput, $limit: Int) {
                                listEvents(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        time
                                        type
                                        origin
                                        action
                                        result
                                        reason
                                        detail
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)}
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.events = (await response.json()).data.listEvents;
                    // ADD REFRESH DATE
                    this.events.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.events.nextToken) {
                        this.$emit('alert', 'More events are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain events.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain events.', 'Account', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextEvents() {
            if (!this.events.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEvents($filter: TableEventFilterInput, $limit: Int, $nextToken: String) {
                                listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        time
                                        type
                                        origin
                                        action
                                        result
                                        reason
                                        detail
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)},
                            "nextToken": "${this.events.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const events = (await response.json()).data.listEvents;
                    // ADD NEW EVENTS
                    for (const event of events.items) {
                        this.events.items.push(event);
                    }
                    // SET NEXT TOKEN
                    this.events.nextToken = events.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (events.nextToken) {
                        this.$emit('alert', 'More events are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Account', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain events.', 'Account', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain events.', 'Account', 'danger');
            }
            this.loading_more = undefined;
        }
    }
}
</script>