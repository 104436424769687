<template>
    <b-card-group deck>

        <!-- FACTOR -->
        <b-card v-if="$store.state.factors && $store.state.factors.items.length < 2" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/play.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Getting Started</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Get started by creating a factor. A factor provides your users / clients with a method to login and signup.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" v-on:click="$emit('show', 'create-factor', { config: {} })" variant="success">Create</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://documenter.getpostman.com/view/18129777/UVXgMHEv#4304e3f2-3499-4444-a79b-4543bdcd8639" target="_blank">Management API</a></small>
            </b-card-footer>
        </b-card>

        <!-- CLIENTS -->
        <b-card v-if="$store.state.clients && $store.state.clients.items.length < 4" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/play.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Getting Started</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Get started by creating an application. An application needs API access for itself or a user's behalf.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" v-on:click="$emit('show', 'create-account', { subtype: 'client', label: 'Application', status: 'DISABLED', config: { internal: true, authentication: {} } })" variant="success">Create</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://documenter.getpostman.com/view/18129777/UVXgMHEv#4304e3f2-3499-4444-a79b-4543bdcd8639" target="_blank">Management API</a></small>
            </b-card-footer>
        </b-card>

        <!-- STATISTICS (SIGNUPS) -->
        <b-card v-if="$store.state.tenant?.statistics?.signups" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/account-pending.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Total Signups</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Statistics are processed every 24 hours.</small>
            </b-card-header>
            <b-list-group flush>
                <b-list-group-item v-for="signups in getSignups(3)" v-bind:key="signups.result" class="d-flex justify-content-between align-items-center">
                    {{ signups.result }}
                    <b-badge variant="white" :class="`text-${signups.variant}`">{{ signups.count }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.tenant.statistics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- STATISTICS (ACCOUNTS) -->
        <b-card v-if="$store.state.tenant?.statistics" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/account.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Total Accounts</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Statistics are processed every 24 hours.</small>
            </b-card-header>
            <b-list-group flush>
                <b-list-group-item v-for="accounts in getAccounts(3)" v-bind:key="accounts.status" class="d-flex justify-content-between align-items-center">
                    {{ accounts.status }}
                    <b-badge variant="white" :class="`text-${accounts.variant}`">{{ accounts.count }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.tenant.statistics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- STATISTICS (LOGINS) -->
        <b-card v-if="$store.state.tenant?.statistics?.logins" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/login.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Total Logins</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Statistics are processed every 24 hours.</small>
            </b-card-header>
            <b-list-group flush>
                <b-list-group-item v-for="logins in getLogins(3)" v-bind:key="logins.type" class="d-flex justify-content-between align-items-center">
                    {{ logins.type }}
                    <b-badge variant="white">{{ logins.count }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.tenant.statistics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>
        
        <!-- METRICS (MAA) -->
        <b-card v-if="$store.state.tenant?.metrics" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/account.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Monthly Active Accounts</span>
                <b-badge class="ml-2" variant="danger">METRIC</b-badge>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Metrics are processed every 5 minutes.</small>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        This is the number of active accounts.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="pt-0">
                <b-row>
                    <b-col class="text-center">
                        <h1>{{ $store.state.tenant.metrics.maa }} <small v-if="$store.state.tenant.statistics?.accounts">
                            <small><small>(±{{ Math.round(($store.state.tenant.metrics.maa / $store.state.tenant.statistics.accounts.enabled) * 100) }}%)</small></small>
                        </small></h1>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.tenant.metrics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- STATISTICS (FACTORS) -->
        <b-card v-if="$store.state.factors" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/factors.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Total Factors</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Statistics are processed every 24 hours.</small>
            </b-card-header>
            <b-list-group flush>
                <b-list-group-item v-for="factor in getFactors(factor => factor.statistics.enrollments, 3)" v-bind:key="factor.id" :to="`/factors/${factor.id}`" class="d-flex justify-content-between align-items-center">
                    {{ $store.state.factors.items.find(f => f.id === factor.id)?.label }}
                    <b-badge variant="white" class="text-primary">{{ factor.count }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.factors.items[0]?.statistics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- STATISTICS (FACTORS) -->
        <b-card v-if="$store.state.factors" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/factors.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Most Used Factors</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Statistics are processed every 24 hours.</small>
            </b-card-header>
            <b-list-group flush>
                <b-list-group-item v-for="factor in getFactors(getUsage, 3)" v-bind:key="factor.id" :to="`/factors/${factor.id}`" class="d-flex justify-content-between align-items-center">
                    {{ $store.state.factors.items.find(f => f.id === factor.id)?.label }}
                    <b-badge variant="white" class="text-primary">{{ factor.subtype.startsWith('oauth2') ? '±' : '' }}{{ factor.count }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.factors.items[0]?.statistics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- STATISTICS (FACTORS) -->
        <b-card v-if="$store.state.factors" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/factors.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Most Successful Factors</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Statistics are processed every 24 hours.</small>
            </b-card-header>
            <b-list-group flush>
                <b-list-group-item v-for="factor in getFactors(factor => factor.statistics.signups.success + factor.statistics.logins.success, 3)" v-bind:key="factor.id" :to="`/factors/${factor.id}`" class="d-flex justify-content-between align-items-center">
                    {{ $store.state.factors.items.find(f => f.id === factor.id)?.label }}
                    <b-badge variant="white" class="text-success">{{ factor.count }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.factors.items[0]?.statistics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- STATISTICS (FACTORS) -->
        <b-card v-if="$store.state.factors" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/factors.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Most Failed Factors</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Statistics are processed every 24 hours.</small>
            </b-card-header>
            <b-list-group flush>
                <b-list-group-item v-for="factor in getFactors(factor => factor.statistics.signups.failed + factor.statistics.logins.failed, 3)" v-bind:key="factor.id" :to="`/factors/${factor.id}`" class="d-flex justify-content-between align-items-center">
                    {{ $store.state.factors.items.find(f => f.id === factor.id)?.label }}
                    <b-badge variant="white" class="text-danger">{{ factor.count }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.factors.items[0]?.statistics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

         <!-- STATISTICS (API CALLS) -->
         <b-card v-if="$store.state.tenant?.statistics" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/code.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Total API Calls</span>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Statistics are processed every 24 hours.</small>
            </b-card-header>
            <b-list-group flush>
                <b-list-group-item v-for="calls in getCalls(3)" v-bind:key="calls.result" class="d-flex justify-content-between align-items-center">
                    {{ calls.result }}
                    <b-badge variant="white" :class="`text-${calls.variant}`">{{ calls.count }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.tenant.statistics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- METRICS (MAC) -->
        <b-card v-if="$store.state.tenant?.metrics" class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/code.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Monthly API Calls</span>
                <b-badge class="ml-2" variant="danger">METRIC</b-badge>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Metrics are processed every 5 minutes.</small>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        This is the number of metered calls.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="pt-0">
                <b-row>
                    <b-col class="text-center">
                        <h1 :class="`text-${getVariant()}`">{{ $store.state.tenant.metrics.mac }} <small v-if="$store.state.tenant.statistics?.api_calls && ['warning','danger'].includes(getVariant())">
                            <small><small>(High)</small></small>
                        </small></h1>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Last updated at {{ new Date($store.state.tenant.metrics.updated_at).toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- DOCUMENTATION -->
        <b-card class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/documentation.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Explore Our Docs</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Explore our product documentation to better understand certain concepts as well as how to realise all your projects.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://docs.quasr.io" target="_blank" variant="outline-primary">Explore</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://gitbook.com" target="_blank">GitBook</a></small>
            </b-card-footer>
        </b-card>

        <!-- API -->
        <b-card class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/code.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Explore Our API</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Explore our API collection. We have an Authentication API which is REST, and a Management API which is GraphQL.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://documenter.getpostman.com/view/18129777/UVXgMHEv" target="_blank" variant="outline-primary">Explore</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://postman.com" target="_blank">Postman</a></small>
            </b-card-footer>
        </b-card>

        <!-- SCHEMA -->
        <b-card class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/icons/schema.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Explore Our Schema</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Explore our GraphQL schema visually. Learn what concepts are available and how they're connected to each other.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://quasr-io.github.io/graphql/" target="_blank" variant="outline-primary">Explore</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://github.com/graphql-kit/graphql-voyager" target="_blank">GraphQL Voyager</a> & <a href="https://github.com/" target="_blank">GitHub</a></small>
            </b-card-footer>
        </b-card>

        <!-- COMMUNITY -->
        <b-card class="shadow rounded bg-white m-2" style="min-width: 20rem; max-width: 20rem;" no-body>
            <b-card-header>
                <b-img src="/img/menu/community.svg" height="20px" width="20px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <span class="text-secondary">Join Our Community</span>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="text-center">
                        Join our community for free support, announcements, release notes, peer interaction or to provide us feedback.
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button class="ml-auto" href="https://discord.com/channels/895325971278856292/895413575491936257" target="_blank" variant="outline-primary">Join</b-button>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://discord.com" target="_blank">Discord</a></small>
            </b-card-footer>
        </b-card>

    </b-card-group>
</template>

<!--
SCRIPT
-->
<script>

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'Dashboard',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'next' ],

    /**
     * DATA
     */
    data() {
        return {
            // TODO
        }
    },

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.$store.state.tenant) {
                    this.$emit('load', 'tenant');
                }
                if (!this.$store.state.factors) {
                    this.$emit('load', 'factors', true); // LOAD ALL
                } else if (this.$store.state.factors.nextToken) {
                    this.$emit('next', 'factors', this.$store.state.factors.nextToken, true); // LOAD ALL
                }
                if (!this.$store.state.clients) {
                    this.$emit('load', 'clients');
                }
            }
        },

        /**
         * USAGE
         */
        getVariant() {
            const usage = (this.$store.state.tenant.metrics.mac / (this.$store.state.tenant.metrics.maa * 10)) * 100;
            if (usage > 100) return 'danger';
            if (usage > 80) return 'warning';
            return 'success';
        },

        /**
         * ACCOUNTS
         */
        getAccounts(max) {

            // Prepare accounts
            const accounts = [
                {
                    status: 'Pending',
                    variant: 'warning',
                    count: this.$store.state.tenant.statistics.accounts.pending
                },
                {
                    status: 'Enabled',
                    variant: 'success',
                    count: this.$store.state.tenant.statistics.accounts.enabled
                },
                {
                    status: 'Disabled',
                    variant: 'black',
                    count: this.$store.state.tenant.statistics.accounts.disabled
                },
                {
                    status: 'Locked',
                    variant: 'danger',
                    count: this.$store.state.tenant.statistics.accounts.locked
                }
            ];

            // Sort accounts
            accounts.sort((a,b) => b.count - a.count);

            // Slice accounts
            return accounts.slice(0, max);

        },

        /**
         * CALSS
         */
        getCalls(max) {

            // Prepare calls
            const calls = [
                {
                    result: 'Pending',
                    variant: 'warning',
                    count: this.$store.state.tenant.statistics.api_calls.pending
                },
                {
                    result: 'Success',
                    variant: 'success',
                    count: this.$store.state.tenant.statistics.api_calls.success
                },
                {
                    result: 'Failed',
                    variant: 'danger',
                    count: this.$store.state.tenant.statistics.api_calls.failed
                }
            ];

            // Sort calls
            calls.sort((a,b) => b.count - a.count);

            // Slice calls
            return calls.slice(0, max);

        },

        /**
         * LOGINS
         */
        getLogins(max) {

            // Prepare logins
            const logins = [
                {
                    type: 'Browser',
                    count: this.$store.state.tenant.statistics.logins.browser
                },
                {
                    type: 'Client',
                    count: this.$store.state.tenant.statistics.logins.client
                },
                {
                    type: 'Refresh',
                    count: this.$store.state.tenant.statistics.logins.refresh
                },
                {
                    type: 'Native',
                    count: this.$store.state.tenant.statistics.logins.native
                }
            ];

            // Sort logins
            logins.sort((a,b) => b.count - a.count);

            // Slice logins
            return logins.slice(0, max);

        },

        /**
         * SIGNUPS
         */
        getSignups(max) {

            // Prepare signups
            const signups = [
                {
                    result: 'Pending',
                    variant: 'warning',
                    count: this.$store.state.tenant.statistics.signups.pending
                },
                {
                    result: 'Success',
                    variant: 'success',
                    count: this.$store.state.tenant.statistics.signups.success
                },
                {
                    result: 'Failed',
                    variant: 'danger',
                    count: this.$store.state.tenant.statistics.signups.failed
                }
            ];

            // Sort signups
            signups.sort((a,b) => b.count - a.count);

            // Slice signups
            return signups.slice(0, max);

        },

        /**
         * FACTORS
         */
        getFactors(count, max) {
            
            // Clone factors
            const factors = this.$store.state.factors.items.map(factor => ({
                id: factor.id,
                count: count(factor),
                subtype: factor.subtype
            }));

            // Sort factors
            factors.sort((a,b) => b.count - a.count);

            // Slice factors
            return factors.slice(0, max);

        },

        getUsage(factor) {
            if (factor.subtype.startsWith('oauth2')) {
                return (factor.statistics.signups.success + factor.statistics.logins.success) + (factor.statistics.signups.failed + factor.statistics.logins.failed) + Math.max((factor.statistics.signups.pending + factor.statistics.logins.pending) - ((factor.statistics.signups.success + factor.statistics.logins.success) * 2) - (factor.statistics.signups.failed + factor.statistics.logins.failed), 0);
            } else {
                return (factor.statistics.signups.success + factor.statistics.logins.success) + (factor.statistics.signups.failed + factor.statistics.logins.failed) + Math.max((factor.statistics.signups.pending + factor.statistics.logins.pending) - (factor.statistics.signups.success + factor.statistics.logins.success) - (factor.statistics.signups.failed + factor.statistics.logins.failed), 0);
            }
        }
    }
}
</script>