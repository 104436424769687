<template>
    <b-overlay :show="loading || loading2">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading || loading2 }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/attributes.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Attribute</h3>
            </b-card-header>
            <RouterView v-slot="{ Component }">
                <component :is="Component" :filter="filter" :variant="variant" :attribute="attribute" :sources="sources" :claims="claims" @alert="sendAlert" @login="sendLogin" @loading="setLoading" @load="sendLoad" @next="sendNext" @show="sendShow" :loading_more="loading_more"/>
            </RouterView>
        </b-card>

        <!-- SET FILTER -->
        <b-modal id="set-filter" title="Set Filter" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Value" label-align-sm="right" label-cols-sm="3" description="Please note field is not case sensitive." :state="value_input?.length > 1" invalid-feedback="Please provide a least 2 characters.">
                        <b-input-group>
                            <b-form-input v-model="value_input" :state="value_input?.length > 1"></b-form-input>
                            <b-input-group-append>
                                <b-button v-on:click="value_input = undefined" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-row class="w-100">
                    <b-col class="d-flex px-0">
                        <b-button variant="outline-secondary" v-on:click="$bvModal.hide('set-filter')">Cancel</b-button>
                        <b-button variant="success" class="ml-auto" v-on:click="updateFilter()" :disabled="value_input?.length < 2">Update</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'AttributeCard',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // CLAIMS
            claims: { items: [] },
            // LOADING
            loading2: undefined,
            // ATTRIBUTE
            attribute: undefined,
            // LOADING (MORE)
            loading_more: false,
            // VALUE (INPUT)
            value_input: undefined,
            // VALUE (SEARCH)
            value_search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.attribute) {
                this.getAttribute();
            }
        },

        /**
         * LOADING
         */
        setLoading(loading) {
            this.loading2 = loading;
        },

        /**
         * EVENTS
         */
        sendAlert(...args) {
            this.$emit('alert', ...args);
        },

        sendLogin(...args) {
            this.$emit('login', ...args);
        },

        sendLoad(...args) {
            if (args[0] === 'attribute') {
                this.getAttribute();
            } else if (args[0] === 'sources') {
                this.listSources();
            } else if (args[0] === 'claims') {
                this.updateFilter();
            } else {
                this.$emit('load', ...args);
            }
        },

        sendNext(...args) {
            if (args[0] === 'claims') {
                this.nextClaims();
            } else if (args[0] === 'sources') {
                this.nextSources();
            } else {
                this.$emit('next', ...args);
            }
        },

        sendShow(...args) {
            if (args[0] === 'set-filter') {
                this.$bvModal.show('set-filter');
            } else {
                this.$emit('show', ...args);
            }
        },

        /**
         * FILTER
         */
        updateFilter() {
            this.$bvModal.hide('set-filter');
            this.value_search = this.value_input;
            this.searchClaims();
        },

        /**
         * ATTRIBUTE
         */
        async getAttribute() {
            this.loading2 = 'Loading';
            try {

                // GET ATTRIBUTE
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getAttribute($id: ID!) {
                                getAttribute(id: $id) {
                                    id
                                    label
                                    value
                                    score
                                    status
                                    subtype
                                    config {
                                        require_validation_for_enablement
                                        case_sensitive
                                        internal
                                        unique
                                        regex
                                        expires_in
                                        restricted
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.attribute = (await response.json()).data.getAttribute;
                    // SET DATES
                    this.attribute.created_at = this.attribute.created_at ? new Date(this.attribute.created_at) : undefined;
                    this.attribute.updated_at = this.attribute.updated_at ? new Date(this.attribute.updated_at) : undefined;
                    this.attribute.expires_at = this.attribute.expires_at ? new Date(this.attribute.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.attribute.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain attribute.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain attribute.', 'Attribute', 'danger');
            }
            this.loading2 = undefined;
        },

        /**
         * CLAIMS
         */
        async searchClaims() {
            if (!this.value_search) return;
            this.loading2 = 'Loading';
            try {

                // LIST CLAIMS
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query searchClaims($attribute: ID!, $value: String!, $limit: Int) {
                                searchClaims(attribute: $attribute, value: $value, limit: $limit) {
                                    items {
                                        id
                                        label
                                        value
                                        status
                                        account
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "attribute": "${this.$route.params.id}",
                            "value": "${this.value_search}",
                            "limit": 50
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.claims = (await response.json()).data.searchClaims;
                    // ADD REFRESH DATE
                    this.claims.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.claims.nextToken) {
                        this.$emit('alert', 'More attribute are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Attribute', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain attributes.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain attributes.', 'Attribute', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextClaims() {
            if (!this.value_search || !this.claims.nextToken) return;
            this.loading_more = true;
            try {

                // LIST CLAIMS
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query searchClaims($attribute: ID!, $value: String!, $limit: Int, $nextToken: String) {
                                searchClaims(attribute: $attribute, value: $value, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        value
                                        status
                                        account
                                        created_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "attribute": "${this.$route.params.id}",
                            "value": "${this.value_search}",
                            "limit": 50,
                            "nextToken": "${this.claims.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const claims = (await response.json()).data.searchClaims;
                    // ADD NEW EVENTS
                    for (const claim of claims.items) {
                        this.claims.items.push(claim);
                    }
                    // SET NEXT TOKEN
                    this.claims.nextToken = claims.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (claims.nextToken) {
                        this.$emit('alert', 'More attributes are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Attribute', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain attributes.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain attributes.', 'Attribute', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * SOURCES
         */
        async listSources() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listSources($filter: TableSourceFilterInput, $limit: Int) {
                                listSources(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        claim
                                        factor
                                        created_at
                                        status
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "attribute": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.sources = (await response.json()).data.listSources;
                    // ADD REFRESH DATE
                    this.sources.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.sources.nextToken) {
                        this.$emit('alert', 'More sources are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Attribute', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain sources.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain sources.', 'Attribute', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextSources() {
            if (!this.sources.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listSources($filter: TableSourceFilterInput, $limit: Int, $nextToken: String) {
                                listSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        claim
                                        factor
                                        created_at
                                        status
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "attribute": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.events.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const sources = (await response.json()).data.listSources;
                    // ADD NEW SOURCES
                    for (const source of sources.items) {
                        this.sources.items.push(source);
                    }
                    // SET NEXT TOKEN
                    this.sources.nextToken = sources.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (sources.nextToken) {
                        this.$emit('alert', 'More sources are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Attribute', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain sources.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain sources.', 'Attribute', 'danger');
            }
            this.loading_more = undefined;
        }
    }
}
</script>